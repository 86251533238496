import { GoogleOAuthProvider } from "@react-oauth/google";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React, { useEffect } from "react";
import { MapkitProvider } from "react-mapkit";
import { useDispatch } from "react-redux";
import Toast from "./components/common/Toast";
import { AuthContextProvider } from "./features/authentication";
import "./index.css";
import IndexRoutes from "./routes";
import { getCategories } from "./store/thunks/categoryThunk";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);
  
  

  return (
    <>
      <MapkitProvider
        tokenOrCallback={`${process.env.REACT_APP_BACKEND_URL}/v1/apple-mapkit/token`}
      >
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <Toast />
          <AuthContextProvider>
            <IndexRoutes />
          </AuthContextProvider>
        </GoogleOAuthProvider>
      </MapkitProvider>
    </>
  );
};
export default App;
