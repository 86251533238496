import axios from "axios";

const call = axios.create({
  // Development
  baseURL: process.env.REACT_APP_BACKEND_URL + "/v1",
  // Production
  //baseURL: "https://gig-falcon-cccb1804d8d6.herokuapp.com/v1",

  //baseURL: "http://localhost:4000/v1",
  timeout: 60000,
});

const baseRequest = ({ method, url, headers, data }) =>
  call({
    method,
    url,
    data,
    headers: {
      ...headers,
      "x-access-token": localStorage.getItem("x-access-token"),
    },
  });

export { call };

export default {
  post: (url = "", data = {}, headers = {}) =>
    baseRequest({ method: "post", headers, url, data }),
  put: (url, data, headers = {}) =>
    baseRequest({ method: "put", headers, url, data }),
  get: (url, data, headers = {}) =>
    baseRequest({ method: "get", headers, url, data }),
  delete: (url, data, headers = {}) =>
    baseRequest({ method: "delete", headers, url, data }),
};
