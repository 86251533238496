import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "../../api/axios";
import { openErrorMessage } from "../slices/toastSlice";

export const getReviews = createAsyncThunk(
  "reviews/getReviews",
  /**
   * @param {object} data
   * @param {string} data.createdTo
   * @param {string} data.gig
   */
  async (data, { rejectWithValue }) => {
    try {
      const { createdTo, gig, reviewFor } = data;
      const filters = {
        createdTo: createdTo || "",
        gigId: gig || "",
        reviewFor: reviewFor || "",
      };

      let queryString = "";
      for (let key in filters) {
        queryString = queryString + `${key}=${filters[key]}&`;
      }

      const response = await axios.get(`/reviews?${queryString}`);
      return response.data.reviews;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createReviewFromBuyer = createAsyncThunk(
  "reviews/createReviewFromBuyer",
  /**
   *
   * @param {Object} data
   * @param {String} data.gigId
   * @param {String} data.comment
   * @param {Number} data.rating // 0-5
   * @param {String} data.orderId
   */
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post("/reviews", data);
      return response.data.review;
    } catch (error) {
      dispatch(openErrorMessage(error.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createReviewFromSeller = createAsyncThunk(
  "reviews/createReviewFromSeller",
  /**
   *
   * @param {Object} data
   * @param {String} data.jobId
   * @param {String} data.comment
   * @param {Number} data.rating // 0-5
   * @param {String} data.orderId
   */
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post("/reviews/seller", data);
      return response.data.review;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
