import styled from "styled-components";

export const Styles = styled.div`
  .location-filter {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding: 0.8rem;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    border-bottom: 1px solid ${({ colors }) => colors.color.lightGrey};
  }

  .location-filter-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-radius: 20px;
    padding: 10px;
    background: white;
    max-width: 800px;
    margin: 0 auto;
  }

  .search-section {
    width: 100%;
    order: 1;
    display: flex;
    justify-content: center;
  }

  .switches-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    order: 2;
  }

  .search-location {
    width: 100%;
    max-width: 300px;
  }

  .locations-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 5px 15px;
    box-shadow: 0 0 5px 2px rgba(233, 229, 229, 0.9);
    border-radius: 20px;
  }

  .form-check-input:checked {
    background-color: ${({ colors }) => colors.color.secondary} !important;
    border-color: ${({ colors }) => colors.color.secondary} !important;
  }

  .form-check-input {
    border-color: ${({ colors }) => colors.color.lightGrey} !important;
    height: 20px !important;
    width: 40px !important;
    cursor: pointer;

    &:focus {
      border-color: none !important;
      background-img: none !important;
      box-shadow: none !important;
    }
  }

  .search-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ colors }) => colors.color.secondary};
    height: 40px;
    width: 40px;
    border-radius: 50%;
    cursor: pointer;
  }

  .search-icon {
    width: 20px;
    height: 20px;
    color: white;
    fill: white;
  }

  .search-close {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ colors }) => colors.color.secondary};
    height: 30px;
    width: 30px !important;
    border-radius: 50%;
    cursor: pointer;
  }

  .custom-select__control {
    background-color: red !important;
    border-color: #ccc;
    border-radius: 4px;
    border-width: 1px;
    padding: 8px;
  }

  @media (min-width: 768px) {
    .location-filter {
      padding: 0.8rem;
    }

    .location-filter-wrapper {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 12px 15px;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 4px 16px 0px;
      gap: 15px;
    }

    .search-section {
      flex: 0.4;
      order: unset;
      min-width: 250px;
      max-width: 300px;
      justify-content: flex-start;
    }

    .switches-section {
      flex: 0.6;
      justify-content: flex-end;
      order: unset;
      max-width: 350px;
    }

    .search-location {
      width: 100%;
      max-width: unset;
    }
  }

  @media (min-width: 1024px) {
    .location-filter-wrapper {
      gap: 25px;
    }
  }
`;
