import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useQBContext } from "../quickblox";
import { SessionHandlers } from "./SessionHandlers";
const AuthContext = createContext({
  /**
   * @type {import('../../types/server').UserAuth | null | undefined}
   */
  user: null,
  status: "loading",
  updatingProfile: false,
  isAuthenticated: false,
  signIn: async () => {},
  signInWithGoogle: async () => {},
  signUp: async () => {},
  logOut: async () => {},
  verifyOTP: async () => {},
  confirmAccount: async () => {},
  changePassword: async () => {},
  toggleFavourites: async () => {},
  updateProfile: async () => {},
  becomeSeller: async () => {},
});

export function AuthContextProvider({ children }) {
  const [state, setState] = useState({
    user: null,
    status: "loading",
    isAuthenticated: false,
    updatingProfile: false,
  });
  const { QBApp } = useQBContext();
  const { initalSession, ...rest } = useMemo(
    () =>
      SessionHandlers({
        QBApp,
        onSessionInitalized: ({ user, isAuthenticated }) => {
          setState({ user, status: "success", isAuthenticated });
        },
        onSessionCreated: ({ user, isAuthenticated }) => {
          setState({ user, status: "success", isAuthenticated });
        },
        onSessionDestroyed: ({ user, isAuthenticated }) => {
          setState({ user, status: "success", isAuthenticated });
        },
        onUserUpdated: (user) => {
          setState((state) => ({ ...state, user }));
        },
      }),
    [QBApp]
  );

  useEffect(() => {
    initalSession();
  }, [initalSession]);

  return (
    <AuthContext.Provider value={{ ...state, ...rest }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext(props) {
  const { user, isAuthenticated, ...rest } = useContext(AuthContext);
  if (props?.required && !isAuthenticated) {
    throw new Error("Authentication is required");
  }

  return { user, isAuthenticated, ...rest };
}
