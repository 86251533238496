import { isFulfilled } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useMap } from "react-mapkit";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { defaultState, updateFilters } from "../slices/homeFiltersSlice.js";
import {
  setGeometryFilterToCurrentLocation,
  setGooglePlaceFilterWithPlaceId,
} from "../thunks/homeFiltersThunk.js";

/**
 * @template T
 * @typedef {{ [P in keyof T]: T[P] | null }} Nullable<T>
 */

const useHomeFilters = () => {
  const { map } = useMap();
  const [searchParams, setSearchParams] = useSearchParams();
  const filters = useSelector(
    (state) =>
      /** @type {import('../slices/homeFiltersSlice.js').HomeFilterState} **/ (
        state.homeFilters
      )
  );
  const dispatch = useDispatch();

  /**
   *
   * @param {Nullable<Partial<import('../slices/homeFiltersSlice.js').FiltersParams>>} filters
   */
  const handleUpdatingFilters = async (filters) => {
    if (filters.sectionFilter === null) {
      searchParams.delete("section");
      dispatch(
        updateFilters({
          sectionFilter: defaultState.sectionFilter,
        })
      );
    } else if (filters.sectionFilter !== undefined) {
      dispatch(
        updateFilters({
          sectionFilter: filters.sectionFilter,
        })
      );
      searchParams.set("section", filters.sectionFilter);
    }

    if (filters.googlePlaceFilter === null) {
      searchParams.delete("placeId");
      dispatch(
        updateFilters({
          googlePlaceFilter: defaultState.googlePlaceFilter.data,
        })
      );
    } else if (filters.googlePlaceFilter !== undefined) {
      searchParams.set("placeId", filters.googlePlaceFilter.value.place_id);
      dispatch(
        updateFilters({
          googlePlaceFilter: filters.googlePlaceFilter,
        })
      );
      // This is to get geometry
      const action = await dispatch(
        setGooglePlaceFilterWithPlaceId(
          filters.googlePlaceFilter.value.place_id
        )
      );
      if (isFulfilled(action)) {
        const geometry = {
          lat: action.payload.value.geometry.location.lat(),
          lng: action.payload.value.geometry.location.lng(),
        };
        dispatch(
          updateFilters({
            geometryFilter: geometry,
          })
        );
      }
    }

    if (filters.applePlaceFilter !== undefined) {
      const latitude = filters.applePlaceFilter.coordinate.latitude;
      const longitude = filters.applePlaceFilter.coordinate.longitude;
      searchParams.set("lat", latitude);
      searchParams.set("lng", longitude);
      const geometry = {
        lat: latitude,
        lng: longitude,
      };
      dispatch(
        updateFilters({
          geometryFilter: geometry,
        })
      );
    }

    if (filters.geometryFilter === null) {
      dispatch(setGeometryFilterToCurrentLocation());
    } else if (filters.geometryFilter !== undefined) {
      dispatch(
        updateFilters({
          geometryFilter: filters.geometryFilter,
        })
      );
    }

    if (filters.gigsFilters !== undefined) {
      dispatch(
        updateFilters({
          gigsFilters: filters.gigsFilters,
        })
      );
    }

    if (filters.jobsFilters !== undefined) {
      dispatch(
        updateFilters({
          jobsFilters: filters.jobsFilters,
        })
      );
    }

    setSearchParams(searchParams);
  };

  useEffect(() => {
    // Assuming window.google.maps will be defined by the time this component is
    // rendered Must be declared in index.html
    // Place Id is defined
    if (window.google && typeof searchParams.get("placeId") === "string") {
      (async () => {
        const action = await dispatch(
          setGooglePlaceFilterWithPlaceId(searchParams.get("placeId"))
        );
        if (!isFulfilled(action)) {
          dispatch(setGeometryFilterToCurrentLocation());
        } else {
          const geometry = {
            lat: action.payload.value.geometry.location.lat(),
            lng: action.payload.value.geometry.location.lng(),
          };
          dispatch(
            updateFilters({
              geometryFilter: geometry,
            })
          );
        }
      })();
    } else if (
      typeof searchParams.get("lat") === "string" &&
      typeof searchParams.get("lng") === "string"
    ) {
      const lat = searchParams.get("lat");
      const lng = searchParams.get("lng");
      const geometry = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      };
      dispatch(
        updateFilters({
          geometryFilter: geometry,
        })
      );
    } else {
      dispatch(setGeometryFilterToCurrentLocation());
    }

    // Disabling this because I don't want to update the filter when the filter is updated using updateFilter
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    filters: {
      ...filters,
      googlePlaceFilter: {
        ...filters.googlePlaceFilter,
        isPlaceIdDefined: typeof searchParams.get("placeId") === "string",
      },
    },
    handleUpdatingFilters,
  };
};

export default useHomeFilters;
