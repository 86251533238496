import { useGoogleLogin } from "@react-oauth/google";
import { useFormik } from "formik";
import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Button from "../../../components/common/Button";
import InputField from "../../../components/common/InputField";
import { useAuthContext } from "../../../features/authentication";
import { openErrorMessage } from "../../../store/slices/toastSlice";
import RoutesPath from "../../../utils/Paths";
const validationSchema = Yup.object({
  email: Yup.string().email("Invalid Email").required("Email is Required"),
  password: Yup.string().required("Password is Required"),
});

const initialValues = {
  email: "",
  password: "",
};

export const SignInForm = ({ onSuccess }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  const { signIn, signInWithGoogle } = useAuthContext();

  const handleGoogleSignIn = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setIsGoogleLoading(true);
      const accessToken = tokenResponse.access_token;
      const response = await signInWithGoogle(accessToken);
      onSuccess && onSuccess(response.data.user);
      setIsGoogleLoading(false);
    },
    onError: (error) => {
      console.log(error);
      setIsGoogleLoading(false);
    },
  });

  const {
    errors,
    values,
    handleChange,
    isSubmitting,
    handleSubmit,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsGoogleLoading(true);
        const response = await signIn({ ...values });
        onSuccess && onSuccess(response.data.user);
      } catch (error) {
        dispatch(openErrorMessage(error.response.data.error));
        setServerError(error.response.data.error);
      } finally {
        setIsGoogleLoading(false);
      }
    },
  });

  return (
    <>
      <div
        className="d-flex flex-column justify-content-between mb-2"
        style={{ minHeight: "160px" }}
      >
        <div
          style={{ width: 200, cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          <img
            className="img-fluid"
            src={"/logo-horizontal.png"}
            alt={"Logo"}
          />
        </div>
        <div className="d-flex flex-column align-items-start justify-content-start">
          <h2 className="text-2xl font-semibold">Sign in</h2>
          <p className="m-0">
            Don't have an account?{" "}
            <span onClick={() => navigate("/sign-up")}>Sign up</span> or{" "}
            <span onClick={() => navigate("/")}>Continue as Guest</span>
          </p>
        </div>
      </div>

      <div className="mb-3">
        <Button
          onClick={handleGoogleSignIn}
          className="w-100 d-flex align-items-center justify-content-center gap-2 google-signin-btn"
          type="button"
          disabled={isGoogleLoading}
        >
          <FcGoogle size={20} />
          <span className="text-muted p-1">Sign in with Google</span>
        </Button>
      </div>

      <div className="d-flex align-items-center mb-3">
        <div className="flex-grow-1 border-bottom"></div>
        <span className="px-3 text-muted">or</span>
        <div className="flex-grow-1 border-bottom"></div>
      </div>

      <form onSubmit={handleSubmit}>
        {serverError && (
          <div className="alert alert-danger mb-0 mt-2 " role="alert">
            {serverError}
          </div>
        )}
        <div>
          <InputField
            id="email"
            name="email"
            className="signup-input"
            type="email"
            placeholder="Enter your email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email}
          />
          <InputField
            id="password"
            name="password"
            className="signup-input"
            type="password"
            placeholder="Enter your password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.password}
          />
          <div>
            <p
              className="text-end forget-password"
              onClick={() => navigate("/forget-password")}
            >
              Forgot Password ?
            </p>
          </div>
          <div className="d-flex flex-column gap-2">
            <Button
              type="submit"
              className="continue"
              children={
                isSubmitting || isGoogleLoading ? (
                  <div
                    className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                ) : (
                  <p style={{ color: "white", fontSize: "15px" }}>Submit</p>
                )
              }
            />
          </div>
        </div>
      </form>
      <p className="m-0 mt-2">
        By selecting Submit, I agree to GigFalcon's{" "}
        <Link to={RoutesPath.legalTOS}>{"Terms of Service"}</Link>,{" "}
        <Link to={RoutesPath.legalPTOS}>{"Payments Terms of Service"}</Link> and{" "}
        <Link to={RoutesPath.legalNDP}>{"Non-Discrimination Policy"}</Link> and
        acknowledge the <Link to={RoutesPath.legalPP}>{"Privacy Policy"}</Link>.
      </p>

      <style jsx>{`
        .google-signin-btn {
          background-color: white !important;
          color: #757575 !important;
          border: 1px solid #ddd !important;
          transition: background-color 0.3s ease;
        }

        .google-signin-btn:hover {
          background-color: #f5f5f5 !important;
        }

        .border-bottom {
          border-bottom: 1px solid #ddd;
        }

        .text-muted {
          color: #757575;
          font-size: 14px;
        }
      `}</style>
    </>
  );
};
