import axios from "../../../api/axios";

/**
 * Sign in user with provided email and password.
 * @async
 * @param {Object} user - User object containing email and password.
 * @param {string} user.email - User's email.
 * @param {string} user.password - User's password.
 * @returns {Object} - User object containing user details.
 */
export const signIn = async (user) => {
  const response = await axios.post("/users/sign-in/app", {
    email: user.email,
    password: user.password,
  });
  if (response.data.user.accountStatus === "pending") {
    // alert("Please verify your account before login.");
    await axios.post("/users/resend-verification-code", {
      userId: response.data.user._id,
    });
  }
  return response;
};

export const signInWithGoogle = async (accessToken) => {
  const response = await axios.post("/users/sign-in/google", {
    idToken: accessToken,
    src: "web",
  });
  return response;
};
