import React from "react";
import { AiTwotoneStar } from "react-icons/ai";
import { GoDotFill } from "react-icons/go";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../utils/Colors";
import { formatNumberWithCommas } from "../../utils/helperFunctions";
import RoutesPath from "../../utils/Paths";
import Avatar from "../common/Avatar";

/**
 * @typedef {import("../../types/server").JobDetailData} Props
 */
const JobCard2 = (/** @type {Props} **/ props) => {
  const handleJobClick = () => {
    window.open(`${RoutesPath.manageJobs}/${props._id}/detail`, "_blank");
  };

  return (
    <JobCard2Style colors={colors} onClick={handleJobClick}>
      <img
        className="bg-img"
        src={props.attachments[0]?.imgUrl}
        alt="Background"
      />
      <div className="card-body">
        <div className="card-body-header"></div>
        <div>
          <div className="d-flex flex-column">
            <div className="d-flex gap-2">
              <p>
                <Avatar
                  imgSrc={props.createdBy?.profileImg?.imgUrl}
                  name={props.createdBy?.username}
                  width={40}
                  height={40}
                />
              </p>

              <div
                style={{ fontWeight: 600 }}
                className="overflow-hidden avatar-username"
              >
                {props.createdBy?._id && (
                  <Link
                    className="text-truncate username-txt"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    to={`/profile/${props.createdBy?._id}?mode=buyer`}
                    target="_blank"
                  >
                    {props.createdBy?.username}
                  </Link>
                )}
              </div>
            </div>
            <div className="card-title">
              <p>
                {props.description?.length > 56
                  ? `${props.description?.slice(0, 56)}...`
                  : props.description}
              </p>
            </div>
          </div>
          <div className="card-body-footer">
            <div
              className="d-flex gap-2 align-items-center"
              style={{ color: "gray", fontSize: "16px" }}
            >
              <div className="d-flex gap-2">
                <div className="d-flex gap-2">
                  <p>{props.workType === "isOnline" ? "Online" : "Onsite"}</p>
                </div>
                <div>
                  <GoDotFill size={8} />
                </div>
                <div className="d-flex align-items-center gap-2">
                  <p>
                    ${formatNumberWithCommas(props.budgetMin)} - $
                    {formatNumberWithCommas(props.budgetMax)}
                  </p>
                </div>
              </div>

              {props.createdBy.rating > 0 ? (
                <div className="rating" style={{ marginLeft: "16px" }}>
                  <AiTwotoneStar className="star-icon" />
                  <p>{props.createdBy.rating}</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </JobCard2Style>
  );
};
const JobCard2Style = styled.div`
  position: relative;
  width: 280px;
  height: 260px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.03);
  }
  .username-txt {
    color: white !important;
    font-weight: 400;
  }
  .avatar-username {
    &:hover {
      .username-txt {
        text-decoration: underline !important;
      }
    }
  }
  .bg-img {
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 1) 25%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  h4,
  h6,
  p {
    color: white;
  }
  h6 {
    text-shadow: 0 0 3px ${({ colors }) => colors.color.darkGrey};
  }
  .card-body-header {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
  }
  .card-profile {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .fav-icon-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ colors }) => colors.color.white};
    width: 27px;
    height: 27px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .fav-icon {
    width: 20px;
    height: 20px;

    color: ${({ colors }) => colors.color.primary};
  }
  h6 {
    margin: 0;
  }
  h4 {
    font-size: 16px;
    margin: 0 !important;
  }
  .avatar {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 50% !important;
    width: 40px;
    height: 40px;
    overflow: hidden;
  }
  .avatar img {
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
  .card-title {
    margin: 5px 0;
    padding-bottom: 10px;
    border-bottom: 0.5px solid ${({ colors }) => colors.color.lightGrey};
  }
  .card-body-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .rating {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .star-icon {
    fill: gold;
    width: 20px;
    height: 20px;
  }
  .price {
    font-size: 18px;
  }
  .filled {
    color: red;
    fill: red;
  }
`;

export default JobCard2;
