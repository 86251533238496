import axios from "../../../api/axios";
import { becomeSeller } from "./become-seller";
import { changePassword } from "./change-password";
import { confirmAccount } from "./confirm-account";
import { signIn, signInWithGoogle } from "./sign-in";
import { signUp } from "./sign-up";
import { verifyOTP } from "./verify-otp";
export const authAPI = {
  signIn,
  signInWithGoogle,
  signUp,
  confirmAccount,
  changePassword,
  verifyOTP,
  becomeSeller,
  logout: async () => {
    return await axios.get("/users/sign-out");
  },
  /**
   *
   * @param {Object} payload
   * @param {String} payload.type
   * @param {String} payload.id
   * @returns {Promise<{status:string}>}
   */
  makeFavourite: async (payload) => {
    return await axios.post("/users/make-fav", payload);
  },

  changeProfilePic: async (profileImg) => {
    const formData = new FormData();
    formData.append("profileImg", profileImg);
    return await axios.put("/users/profile-img", formData);
  },

  updateProfile: async (payload) => {
    return await axios.post("/users/profile", payload);
  },
};
