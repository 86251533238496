import React, { useState } from "react";

import Button from "../../components/common/Button";
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
import { colors } from "../../utils/Colors";
import { Styles } from "./payment";

import { BsBank } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import BankTransfer from "../../components/payment/BankTransfer";

const Payment = () => {
  const [currentSection, setCurrentSection] = useState(2);
  const [isOpen, setIsOpen] = useState(false);
  const [addCard, setAddCard] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const toggleAddCard = () => setAddCard(!addCard);

  return (
    <>
      <Header />
      <Styles colors={colors}>
        <div className="payment-page">
          <h3>
            <span className="falcon-clr">Payment </span>Details
          </h3>
          <div className="payment-wrapper d-flex flex-column flex-md-row gap-3 jutify-content-start">
            <div className="payment-sidebar d-flex justify-content-center flex-md-column align-items-center justify-content-md-start gap-3">
              {/* <div
                className={`d-flex flex-column align-items-center jutify-content-center box ${
                  currentSection === 1 && "selected"
                }`}
                onClick={() => setCurrentSection(1)}
              >
                <TbBrandPaypal className="paypal-icon" />
                <p className="paypal-text">Paypal</p>
              </div> */}
              <div
                className={`d-flex flex-column align-items-center jutify-content-center box ${
                  currentSection === 2 && "selected"
                }`}
                onClick={() => setCurrentSection(2)}
              >
                <BsBank className="paypal-icon" />
                <p className="paypal-text">Bank Transfer</p>
              </div>
            </div>
            <div className="payment-main-section">
              {currentSection === 1 ? (
                <p className="text-center grey-clr">
                  Paypal Not Available at the moment.
                </p>
              ) : (
                <BankTransfer toast={toast} />
              )}
            </div>
          </div>
          <Modal isOpen={addCard} toggle={toggleAddCard} size="lg">
            <ModalHeader toggle={toggleAddCard}>Add Order Details</ModalHeader>
            <ModalBody>2</ModalBody>
            <ModalFooter>
              <Button className="go-offline px-3 py-1" onClick={toggleAddCard}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>Add Order Details</ModalHeader>
            <ModalBody>
              {/* <div className="form-floating">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                  style={{ height: "150px" }}
                  value={deliveryText}
                  onChange={(e) => setDelivery(e.target.value)}
                ></textarea>
                <Error
                  text={deliveryText.length < 1 && "Details are required"}
                />
                <label htmlFor="floatingTextarea2">Order Details</label>
              </div> */}
            </ModalBody>
            <ModalFooter>
              <Button className="go-offline px-3 py-1" onClick={toggle}>
                Cancel
              </Button>
              {/* <Button
                children={
                  completeLoading ? (
                    <div
                      className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  ) : (
                    "Deliver Now"
                  )
                }
                disabled={!deliveryText.length > 0}
                className="accept-btn px-3 py-1 reject-order-btn"
                onClick={() => {
                  toggle();
                }}
              /> */}
            </ModalFooter>
          </Modal>
        </div>
        <ToastContainer />
      </Styles>
      <Footer />
    </>
  );
};

export default Payment;
