import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import ImagePlaceHolder from "../../../assets/images/img-placeholder.jpeg";
import { rejectRecievedOffer } from "../../../store/thunks/offersThunk";
import { formatNumberWithCommas } from "../../../utils/helperFunctions";
import Button from "../../common/Button";

const JobOffer = ({ offer, handleAccept }) => {
  const [seeMore, setSeeMore] = useState(false);
  const dispatch = useDispatch();
  const { loading: rejectRecievedOfferMutationLoading } = useSelector(
    (state) => state.offers.rejectRecievedOfferMutation
  );
  const attachments = offer?.attachments;

  const link =
    "http://res.cloudinary.com/dfmvcqaol/image/upload/v1687178027/gig-falcon/jobs/offersAttachments/h8h79odd6urzmpaatjwr.jpg";
  const linkWithoutProtocol = link.replace("https://", "");

  const downloadAllFiles = () => {
    attachments.forEach((attachment) => {
      const url = attachment.url;
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.download = url.substring(url.lastIndexOf("/") + 1);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };
  return (
    <div className="offer">
      <div className="d-flex flex-column flex-sm-row gap-3">
        <div className="offer-img">
          <img
            src={offer?.job?.attachments?.[0]?.imgUrl || ImagePlaceHolder}
            alt={"offer"}
          />
        </div>
        <div className="d-lg-flex justify-content-between w-100">
          <div>
            {offer?.coverLetter.length > 40 && !seeMore ? (
              <p className="offer-desc">
                {offer?.coverLetter.slice(0, 40)}
                <span onClick={() => setSeeMore(true)}>...see more</span>
              </p>
            ) : seeMore ? (
              <p className="offer-desc see-more">
                {offer?.coverLetter}
                <span onClick={() => setSeeMore(false)}>...see less</span>
              </p>
            ) : (
              <p className="offer-desc">{offer?.coverLetter}</p>
            )}

            <p className="offer-price">
              Price:{" "}
              <span>
                ${formatNumberWithCommas(offer?.job?.budgetMin)}-$
                {formatNumberWithCommas(offer?.job?.budgetMax)}
              </span>
            </p>
          </div>
          <div className="d-flex flex-lg-column justify-content-between align-items-end">
            <div>
              <p className="offer-price">
                Offered: <span>${formatNumberWithCommas(offer?.price)}</span>
              </p>
              {attachments.length > 0 && (
                <a
                  className="attachments"
                  download="attachment.jpg"
                  href={linkWithoutProtocol}
                  onClick={downloadAllFiles}
                >
                  attachments
                </a>
              )}
            </div>
            {offer.status === "pending" ? (
              <div className="jutify-self-end d-flex gap-2 mt-2">
                <Button
                  className="accept-btn"
                  onClick={() => handleAccept(offer)}
                >
                  {"Accept"}
                </Button>
                <Button
                  className="reject-btn"
                  onClick={() => dispatch(rejectRecievedOffer(offer))}
                >
                  {rejectRecievedOfferMutationLoading ? (
                    <div
                      className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  ) : (
                    "Reject"
                  )}
                </Button>
              </div>
            ) : (
              <div
                className={`justify-self-end d-flex mt-2 offer-status ${offer.status}`}
              >
                <p>Rejected</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-4 text-muted d-flex justify-content-end">
        <small>
          Created:{" "}
          {new Date(offer.createdAt).toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })}
        </small>
      </div>
    </div>
  );
};

export default JobOffer;
