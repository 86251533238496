import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  FilterDropdown,
  FilterDropdownApplyButton,
  FilterDropdownClearButton,
  FilterDropdownMenu,
  FilterDropdownMenuItem,
  FilterDropdownTrigger,
} from "./FilterDropdown";

const DropdownFilter = ({ children, paramId, options, defaultValue = "" }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterValue, setFilterValue] = useState(
    searchParams.get(paramId) || defaultValue
  );

  const handleSortBySubmit = (e) => {
    e.preventDefault();
    setSearchParams((prev) => {
      prev.set(paramId, e.target[paramId].value);
      return prev;
    });
  };
  useEffect(() => {
    setFilterValue(searchParams.get(paramId) || defaultValue);
  }, [searchParams, paramId, defaultValue]);

  return (
    <form method="POST" onSubmit={handleSortBySubmit}>
      <FilterDropdown>
        <FilterDropdownTrigger>{children}</FilterDropdownTrigger>
        <FilterDropdownMenu>
          {options.map((option, index) => (
            <FilterDropdownMenuItem
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setFilterValue(option.value);
              }}
            >
              <p>{option.label}</p>
              <input
                type="radio"
                name={`${paramId}`}
                value={option.value}
                readOnly
                id={index + 1}
                checked={filterValue === option.value}
              />
            </FilterDropdownMenuItem>
          ))}
          <FilterDropdownClearButton
            onClick={() => {
              setSearchParams((prevParams) => {
                prevParams.delete(paramId);
                return prevParams;
              });
            }}
          >
            {"Clear"}
          </FilterDropdownClearButton>
          <FilterDropdownApplyButton type={"submit"}>
            {"Apply"}
          </FilterDropdownApplyButton>
        </FilterDropdownMenu>
      </FilterDropdown>
    </form>
  );
};

export default DropdownFilter;
