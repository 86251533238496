import axios from "./axios";

export const resetPassword = async (oldPassword, newPassword) => {
  const response = await axios.post("/users/reset-password", {
    oldPassword,
    newPassword,
  });
  return response.data;
};

export const getSellerStats = async () => {
  const response = await axios.get("/users/seller-stats");
  return response.data;
};

export const updateSellerStats = async (data) => {
  const response = await axios.put("/users/seller-stats", data);
  return response.data;
};
