import { useQBContext } from "../../context/quickblox-provider";

import { useAuthContext } from "../../../authentication";
import { createSliceWithStateIds } from "../../../react-query-redux/helpers";
import {
  useQueryRedux,
  useQueryReduxState,
} from "../../../react-query-redux/use-query-redux";
import { getUserFromId, listPrivateDialogsOfUser } from "../services";

/**
 * @typedef {{ data: T; status: 'success' | 'loading' | 'error'; }} Type<T>
 * @template {object} T
 */

/**
 * @typedef {object} UserDialog
 * @property {string} created_at
 * @property {number} is_joinable
 * @property {string | null} last_message
 * @property {number | null}  last_message_date_sent
 * @property {string | null} last_message_id
 * @property {string | null} last_message_user_id
 * @property {string} name
 * @property {Array.<number>} occupants_ids
 *
 * @property {object} opponent
 * @property {boolean} opponent.age_over16
 * @property {null} opponent.blob_id
 * @property {string} opponent.created_at
 * @property {string} opponent.custom_data
 * @property {string} opponent.email
 * @property {string} opponent.external_user_id
 * @property {string} opponent.facebook_id
 * @property {string} opponent.full_name
 * @property {string} opponent.id
 * @property {string} opponent.last_request_at
 * @property {string} opponent.login
 * @property {string} opponent.parents_contacts
 * @property {string} opponent.phone
 * @property {string} opponent.updated_at
 * @property {string} opponent.user_tags
 * @property {string} opponent.website
 *
 * @property {string | number} photo
 * @property {number} type
 * @property {number} unread_messages_count
 * @property {string} updated_at
 * @property {string} user_id
 * @property {string} xmpp_room_jid
 * @property {string} _id
 */
/**
 * @typedef {Type<Array.<UserDialog>>} UserDialogStateData
 * @also
 * @typedef {Object<string, UserDialogStateData} UserDialogState
 */

const stateKey = "qb-get-dialogs";
export const useUserDialogsSlice = createSliceWithStateIds(stateKey);
const { reducerName, actions } = useUserDialogsSlice;
export const useUserDialogs = (limit = 10) => {
  const { QBApp } = useQBContext({ required: true });
  const {
    user: { quickblox },
  } = useAuthContext({ required: true });
  const state = useQueryRedux({
    id: [stateKey],
    reducerName,
    actions,
    fetch: async () => {
      const userId = quickblox.user_id;
      const dialogs = await listPrivateDialogsOfUser(QBApp, userId, {
        skip: 0,
        limit: 1000,
      });
      const { items } = dialogs;
      const ItemsPopulated = await Promise.all(
        items.map(async (item) => {
          const opponentId = item.occupants_ids.filter(
            (id) => id !== userId
          )[0];
          try {
            const opponent = await getUserFromId(QBApp, opponentId);
            return {
              ...item,
              opponent,
            };
          } catch (e) {
            return undefined;
          }
        })
      );
      return ItemsPopulated.filter((item) => item !== undefined);
    },
  });
  return {
    /**
     * @type {UserDialogStateData['data']} state
     */
    dialogs: state.data,
    /**
     * @type {UserDialogStateData['status']} state
     */
    status: state.status,
    error: state.error,
  };
};
export const useUserDialogsState = () => {
  const { setStateById, getStateById } = useQueryReduxState({
    actions,
    reducerName,
  });

  return {
    /**
     * @type {UserDialogStateData} state
     * @type {function(Record<string,any>):UserDialogStateData | undefined} getStateById
     */
    getStateByParams: (params) =>
      getStateById(params ? [stateKey, params] : [stateKey]),

    /**
     *
     * @typedef {function(UserDialogStateData | undefined):void} setState
     * @type {function(Record<string,any>,setState: setState):void} setStateById
     */
    setStateByParams: (params, setState) =>
      setStateById(params ? [stateKey, params] : [stateKey], setState),
  };
};
