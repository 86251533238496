import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import StepsImg from "../../../assets/images/gigfalcon-steps.png";
import { useAuthContext } from "../../../features/authentication";
import { openSuccessMessage } from "../../../store/slices/toastSlice";
import { colors } from "../../../utils/Colors";
import RoutesPath from "../../../utils/Paths";
import { AccountVerificationForm } from "../AccountVerificationForm";
import SignUpForm from "./SignUpForm";
import { Styles } from "./style";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAuthContext();
  return (
    <Styles colors={colors}>
      <div className="signup">
        <div className="person-section flex-fill">
          <div className="person-section-image">
            <img src={StepsImg} alt={"Person"} />
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center flex-fill min-vh-100">
          <div style={{ width: "400px" }}>
            {user ? (
              <AccountVerificationForm
                user={user}
                onSuccess={async (user) => {
                  navigate("/");
                  dispatch(openSuccessMessage("Account Verified ! "));
                }}
              />
            ) : (
              <SignUpForm />
            )}
            <p className="m-0 mt-2 text-sm">
              By selecting Agree and Continue, I agree to GigFalcon's{" "}
              <Link to={RoutesPath.legalTOS}>{"Terms of Service"}</Link>,{" "}
              <Link to={RoutesPath.legalPTOS}>
                {"Payments Terms of Service"}
              </Link>{" "}
              and{" "}
              <Link to={RoutesPath.legalNDP}>
                {"Non-Discrimination Policy"}
              </Link>{" "}
              and acknowledge the{" "}
              <Link to={RoutesPath.legalPP}>{"Privacy Policy"}</Link>.
            </p>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default SignUp;
