import styled from "styled-components";

export const Styles = styled.div`
  border-bottom: 1px solid ${({ colors }) => colors.color.lightGrey};
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: ${({ colors }) => colors.color.white};

  /* height: 120px; */
  .heading {
    font-family: "NN_Bold";
  }
  .regular {
    font-family: "NN_SemiBold";
  }
  .grey-clr {
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .falcon-clr {
    color: ${({ colors }) => colors.color.secondary} !important;
  }
  .bell-icon {
    height: 20px;
    width: 20px;
  }
  .notification-dropdown {
    height: 400px;
    width: 270px;
  }
  nav {
    padding: 10px;
  }
  .navbar-shadow {
    box-shadow: 0px 0px 10px 0px ${({ colors }) => colors.color.lightGrey};
  }
  .hamburger {
    width: 25px;
    height: 25px;
    cursor: pointer;
    color: ${({ colors }) => colors.color.primary};
  }
  .nav-content {
    display: none;
  }
  img {
    height: 45px;
    /* margin: 0 0 0 40px; */
    flex: 3;
    cursor: pointer;
  }
  .mobile-user-img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    overflow: hidden;
  }
  .mobile-user-img img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    width: 100% !important;
  }
  .sign-in-btn {
    background-color: ${({ colors }) => colors.color.secondary};
    padding: 5px 10px;
    border-radius: 5px;
    color: ${({ colors }) => colors.color.white};
    font-size: 16px;
    line-height: 1.5;
  }
  .menu-options {
    display: flex;
    align-items: center;
  }
  .post-job-btn {
    display: none;
  }
  .profile-icon {
    display: none;
    width: 40px;
    height: 40px;
    margin: 0 24px;
    color: ${({ colors }) => colors.color.secondary};
    cursor: pointer;
  }
  .user-img {
    display: none;
    width: 40px;
    height: 40px;
    margin: 0;
    border-radius: 50%;
    overflow: hidden;
  }
  .user-img img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    width: 100% !important;
  }
  .home-dropdown {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    border: none;
    border-radius: 10px;
  }
  .dropdown-item:hover {
    background-color: ${({ colors }) => colors.color.lightFalcon};
  }
  .falcon-clr {
    color: ${({ colors }) => colors.color.secondary};
  }

  @media only screen and (min-width: 480px) {
    border-bottom: none;

    .hamburger {
      left: 30px;
    }
    .falcon-logo {
      height: 50px;
      margin-left: 50px;
    }

    @media only screen and (min-width: 768px) {
      .user-img {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .hamburger {
        display: none;
      }
      .sign-in-btn {
        margin-right: 20px;
      }
      .profile-icon {
        display: block;
      }
      .post-job-btn {
        display: block;
        background-color: ${({ colors }) => colors.color.secondary};
        color: ${({ colors }) => colors.color.white};
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
      }
      .sign-in-btn {
        background-color: ${({ colors }) => colors.color.white};
        color: ${({ colors }) => colors.color.black};
        margin-left: 24px;
        cursor: pointer;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid ${({ colors }) => colors.color.secondary};
      }
      .nav-content {
        display: flex;
        justify-content: space-between;
      }
    }
    @media only screen and (min-width: 1024px) {
      img {
        height: 65px;
      }
    }
  }
`;
