import React, { useEffect, useState } from "react";

import styled from "styled-components";
import Footer from "../../../components/common/Footer";
import Header from "../../../components/common/Header";
import GigPlaceholder from "../../../components/common/Placeholders/GigPlaceholder";
import FilterDropdowns from "../../../components/Gigs/FilterDropdowns";
import { filterGigs } from "../../../store/thunks/gigsThunk";
import { colors } from "../../../utils/Colors";
import { Styles } from "./viewGigs";

import { useFormik } from "formik";
import { BsChevronRight } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Categories from "../../../components/common/Categories";
import GigCard from "../../../components/Gigs/GigCard";
import useGigsSelector from "../../../store/hooks/useGigsSelector";

const initialValues = {
  category: "",
  searchText: "",
  sortBy: "",
  level: "",
  minPrice: "",
  maxPrice: "",
  subcategory: "",
  userId: "",
};

const ViewGigs = () => {
  const [categoryIds, setCategoryIds] = useState({
    category: "",
    subcategory: "",
  });
  const dispatch = useDispatch();
  const params = useParams();
  const { gigs, loading } = useGigsSelector();
  const { categories } = useSelector((state) => state.categories);

  useEffect(() => {
    let categoryId = "";
    let subcategoryId = "";
    if (!categories.length > 0) return;
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].name === params.category) {
        categoryId = categories[i]._id;
        for (let j = 0; j < categories[i].subcategories.length; j++) {
          if (categories[i].subcategories[j].name === params.subcategory) {
            subcategoryId = categories[i].subcategories[j]._id;
            setCategoryIds({
              category: categoryId,
              subcategory: subcategoryId,
            });
          }
        }
      }
    }

    dispatch(
      filterGigs({
        category: categoryId,
        subcategory: subcategoryId,
      })
    );
  }, [categories, params, dispatch]);

  const { errors, handleChange, handleSubmit, values, setFieldValue } =
    useFormik({
      initialValues,
      onSubmit: (values) => {
        dispatch(
          filterGigs({
            ...values,
            category: categoryIds.category,
            subcategory: categoryIds.subcategory,
          })
        );
      },
    });

  return (
    <Styles colors={colors}>
      <Header />
      <Categories />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="category">
              <h5>{params.category}</h5>
              <BsChevronRight className="chevron" />
            </div>
            <h3 className="mb-3">{params.subcategory}</h3>
          </div>
          <div className="d-flex justify-content-start">
            <FilterDropdowns
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              values={values}
              errors={errors}
              setFieldValue={setFieldValue}
            />
          </div>
          <div className="col-12">
            <StyledGigList colors={colors}>
              {loading ? (
                <GigPlaceholder
                  placeholders={[1, 2, 3, 4]}
                  className="gig-list"
                />
              ) : loading === false && gigs?.length === 0 ? (
                <p className="text-center mt-4 mb-5 grey-clr">
                  Sorry ... No Gig found.
                </p>
              ) : (
                gigs?.map((gig, index) => <GigCard key={index} {...gig} />)
              )}
            </StyledGigList>
          </div>
        </div>
      </div>
      <Footer />
    </Styles>
  );
};
const StyledGigList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin: 40px 0px;
`;
export default ViewGigs;
