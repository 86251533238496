import React, { useEffect } from "react";

import ImagePlace from "../../../assets/images/img-placeholder.jpeg";
import Footer from "../../../components/common/Footer";
import Header from "../../../components/common/Header";
import {
  cancelPostedOffer,
  getCustomPostedOffers,
  getPostedOffers,
} from "../../../store/thunks/offersThunk";
import { colors } from "../../../utils/Colors";
import { Styles } from "./sentOffers";

import { BsHourglassSplit } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { formatNumberWithCommas } from "../../../utils/helperFunctions";

const SentOffers = () => {
  const [params, setParams] = useSearchParams();
  const currentTab = /** @type {('jobOffers' | 'customOffers')} */ (
    params.get("tab") || "jobOffers"
  );

  const { data: postedOffers, loading: postedOffersLoading } = useSelector(
    (state) => state.offers.getPostedOffers
  );
  const { data: customPostedOffers, loading: customPostedOffersLoading } =
    useSelector((state) => state.offers.getCustomPostedOffers);
  const { loading: cancelPostedOfferMutationLoading } = useSelector(
    (state) => state.offers.cancelPostedOfferMutation
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPostedOffers());
    dispatch(getCustomPostedOffers());
  }, [dispatch]);

  const handleCancelOffer = (offer) => {
    dispatch(cancelPostedOffer(offer));
  };

  return (
    <Styles colors={colors}>
      <Header />
      <div className="container offers-page">
        <h3 className="mb-3">
          <span className="falcon-clr">Sent</span> Offers
        </h3>
        <div className="status-bar">
          <div
            className={
              currentTab === "jobOffers"
                ? "job-offer status-bar-sphere"
                : currentTab === "customOffers" &&
                  "custom-offer status-bar-sphere"
            }
          ></div>
        </div>
        <ul className="manage-status-list">
          <li
            className="manage-status"
            onClick={() =>
              setParams((params) => {
                params.set("tab", "jobOffers");
                return params;
              })
            }
          >
            {"Job Offers"}
          </li>
          <li
            className="manage-status"
            onClick={() =>
              setParams((params) => {
                params.set("tab", "customOffers");
                return params;
              })
            }
          >
            {"Custom Offers"}
          </li>
        </ul>
        <div className="offers-wrapper">
          {currentTab === "jobOffers" ? (
            postedOffersLoading ? (
              <p className="grey-clr text-center p-5">Loading...</p>
            ) : postedOffers?.length === 0 ? (
              <p className="grey-clr text-center p-5">No Offer to Show</p>
            ) : (
              postedOffers?.map((offer, index) => {
                return (
                  offer.status !== "cancelled" && (
                    <div
                      className="d-flex flex-column flex-sm-row gap-3 offer"
                      key={offer._id}
                    >
                      <div className="offer-img">
                        <img
                          src={
                            offer?.job?.attachments?.[0].imgUrl || ImagePlace
                          }
                          alt={"offer"}
                        />
                      </div>
                      <div className="d-lg-flex justify-content-between w-100">
                        <div>
                          <p className="offer-desc">
                            {offer?.job?.description.length > 65
                              ? offer?.job?.description.slice(0, 64) + "..."
                              : offer?.job?.description}
                          </p>
                          <p className="offer-price">
                            Price:{" "}
                            <span>
                              ${formatNumberWithCommas(offer?.job?.budgetMin)}-$
                              {formatNumberWithCommas(offer?.job?.budgetMax)}
                            </span>
                          </p>
                        </div>
                        <div className="d-flex flex-lg-column justify-content-between align-items-end">
                          <div>
                            <p className="offer-price">
                              Offered:{" "}
                              <span>
                                ${formatNumberWithCommas(offer?.price)}
                              </span>
                            </p>
                            {offer?.attachments.length > 0 && (
                              <p className="attachments">attachments</p>
                            )}
                          </div>
                          <div className="d-flex gap-2 align-items-center">
                            <div
                              className={`justify-self-end d-flex mt-2 offer-status ${offer.status}`}
                            >
                              <p>{offer.status}</p>
                            </div>
                            {offer?.status === "pending" && (
                              <div
                                className={`justify-self-end d-flex mt-2 offer-status text-center cancel`}
                                onClick={() => handleCancelOffer(offer)}
                                style={{ minWidth: "70px" }}
                              >
                                <p className="">
                                  {cancelPostedOfferMutationLoading ? (
                                    <div
                                      className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                                      role="status"
                                    >
                                      <span className="sr-only"></span>
                                    </div>
                                  ) : (
                                    "Cancel"
                                  )}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                );
              })
            )
          ) : customPostedOffersLoading ? (
            <p className="grey-clr text-center p-5">Loading...</p>
          ) : customPostedOffers?.length === 0 ? (
            <p className="grey-clr text-center p-5">No Offer to Show</p>
          ) : (
            customPostedOffers?.map((offer, index) => {
              return (
                <div
                  className="d-flex flex-column flex-sm-row gap-3 offer"
                  key={offer._id}
                >
                  <div className="d-lg-flex justify-content-between w-100">
                    <div className="offer-info">
                      <p className="custom-offer-desc">
                        {offer?.selectedGig?.title}
                      </p>
                      <p className="offer-price ">
                        <span>
                          {offer?.coverLetter.length > 50
                            ? offer?.coverLetter.slice(0, 49) + "..."
                            : offer?.coverLetter}
                        </span>
                      </p>
                      <p className="offer-price mt-2">
                        Offered: <span>${offer?.price}</span>
                      </p>
                    </div>
                    <div className="d-lg-flex flex-column justify-content-between align-items-end">
                      <div className="d-flex align-items-center gap-2">
                        <BsHourglassSplit className="grey-clr" />
                        <p className="offer-price">
                          <span>
                            {offer.workingDays > 1
                              ? offer.workingDays + " Days"
                              : offer.workingDays + " Day"}{" "}
                            Delivery
                          </span>
                        </p>
                      </div>
                      <div className="d-flex mt-2">
                        <div className="d-flex gap-2 align-items-center">
                          <div
                            className={`justify-self-end d-flex mt-2 offer-status ${offer.status}`}
                          >
                            <p>{offer.status}</p>
                          </div>
                          {offer?.status === "pending" && (
                            <div
                              className={`justify-self-end d-flex mt-2 offer-status text-center cancel`}
                              onClick={() => handleCancelOffer(offer)}
                              style={{ minWidth: "70px" }}
                            >
                              <p className="">
                                {cancelPostedOfferMutationLoading ? (
                                  <div
                                    className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                                    role="status"
                                  >
                                    <span className="sr-only"></span>
                                  </div>
                                ) : (
                                  "Cancel"
                                )}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      <Footer />
    </Styles>
  );
};

export default SentOffers;
