import React from "react";

import { colors } from "../../../utils/Colors";
import Button from "../../common/Button";
import Error from "../../common/Error";
import InputField from "../../common/InputField";
import { Styles } from "./filterDropdowns";

import { BsChevronDown } from "react-icons/bs";
import { VscDebugRestart } from "react-icons/vsc";

const sorted = [
  {
    label: "Price (high to low)",
    value: "high-low",
  },
  {
    label: "Price (low to high)",
    value: "low-high",
  },
  {
    label: "Show Top Rated first",
    value: "topRated",
  },
];

const levels = ["Rookie", "Intermediate", "Pro", "Master"];

const FilterDropdowns = ({
  handleChange,
  handleSubmit,
  setFieldValue,
  values,
  errors,
  resetForm,
}) => {
  return (
    <Styles colors={colors}>
      <form onSubmit={handleSubmit}>
        <div className="search-bar">
          <div className="input-radio-group">
            <InputField
              className="home-gig-search"
              placeholder="e.g home maintenance"
              name="searchText"
              value={values.searchText}
              onChange={handleChange}
            />

            <div className="d-flex align-items-center locations-wrapper sm-radius">
              <label htmlFor={1} key={1}>
                <div
                  className="location-label d-flex justify-content-between gap-3"
                  key={1}
                  onClick={(e) => e.stopPropagation()}
                >
                  <p className="text-sm">Map Area</p>
                  <input
                    type="radio"
                    name="location"
                    id={1}
                    value="areaWise"
                    checked={values.location === "areaWise"}
                    onChange={(e) => {
                      setFieldValue("location", e.target.value);
                      handleSubmit();
                    }}
                  />
                </div>
              </label>
              <label htmlFor={2} key={2}>
                <div
                  className="location-label d-flex justify-content-between gap-3"
                  key={2}
                  onClick={(e) => e.stopPropagation()}
                >
                  <p className="text-sm">View All</p>
                  <input
                    type="radio"
                    name="location"
                    id={2}
                    value="global"
                    checked={values.location === "global"}
                    onChange={(e) => {
                      setFieldValue("location", e.target.value);
                      handleSubmit();
                    }}
                  />
                </div>
              </label>
            </div>
          </div>
          <Button type="submit" children="Search" className="search-btn" />
        </div>
        <div className="filter d-flex gap-2 flex-wrap">
          <div className="dropdown">
            <div
              className="dropdowns d-flex justify-content-between"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ width: "150px", cursor: "pointer" }}
            >
              <p>Sort By</p>
              <BsChevronDown className="chevron1" />
            </div>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {sorted?.map((option, index) => (
                <label
                  className="w-100"
                  style={{ cursor: "pointer" }}
                  htmlFor={index + 1}
                  key={index}
                >
                  <div
                    className="dropdown-item d-flex justify-content-between gap-3 mb-3"
                    key={option.value}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <p>{option.label}</p>
                    <input
                      type="radio"
                      name="sortBy"
                      id={index + 1}
                      value={option.value}
                      checked={values.sortBy === option.value}
                      onChange={handleChange}
                    />
                  </div>
                </label>
              ))}
              <p
                className="clear-filter"
                onClick={(e) => {
                  setFieldValue("sortBy", "");
                  e.stopPropagation();
                }}
              >
                Clear
              </p>
              <div className="mt-4 mx-3 mb-2">
                <Button children="Apply" type="submit" className="apply-btn" />
              </div>
            </ul>
          </div>

          <div className="dropdown">
            <div
              className="dropdowns d-flex justify-content-between"
              style={{ width: "150px", cursor: "pointer" }}
              id="dropdownMenuButton2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <p>Seller Level</p>
              <BsChevronDown className="chevron1" />
            </div>
            <ul
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton2"
              style={{ minWidth: "188px" }}
            >
              {levels?.map((option, index) => (
                <label
                  className="w-100"
                  style={{ cursor: "pointer" }}
                  htmlFor={index + 5}
                  key={index}
                >
                  <div
                    className="dropdown-item d-flex justify-content-between gap-3 mb-3"
                    key={option}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <p>{option}</p>
                    <input
                      type="radio"
                      name="level"
                      id={index + 5}
                      value={option}
                      checked={values.level === option}
                      onChange={handleChange}
                    />
                  </div>
                </label>
              ))}
              <p
                className="clear-filter"
                onClick={(e) => {
                  setFieldValue("level", "");
                  e.stopPropagation();
                }}
              >
                Clear
              </p>
              <div className="mt-4 mx-3 mb-2">
                <Button children="Apply" type="submit" className="apply-btn" />
              </div>
            </ul>
          </div>

          <div className="dropdown">
            <div
              className="dropdowns d-flex justify-content-between"
              id="dropdownMenuButton3"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ width: "150px", cursor: "pointer" }}
            >
              <p>Budget</p>
              <BsChevronDown className="chevron1" />
            </div>
            <ul
              className="dropdown-menu"
              style={{ width: "188px" }}
              aria-labelledby="dropdownMenuButton3"
            >
              <div onClick={(e) => e.stopPropagation()}>
                <div className="dropdown-item mb-3">
                  <label className="d-block">Min</label>
                  <input
                    className="filter-inputs"
                    type="number"
                    label="Min"
                    name="minPrice"
                    value={values.minPrice}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <div
                  className="dropdown-item"
                  onClick={(e) => e.stopPropagation()}
                >
                  <label className="d-block">Max</label>
                  <input
                    className="filter-inputs"
                    type="number"
                    label="Max"
                    name="maxPrice"
                    value={values.maxPrice}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3 px-3">
                  {errors?.maxPrice && <Error text={errors.maxPrice} />}
                </div>
                <p
                  className="clear-filter"
                  onClick={(e) => {
                    setFieldValue("minPrice", "");
                    setFieldValue("maxPrice", "");
                    e.stopPropagation();
                  }}
                >
                  Clear
                </p>
                <div className="mt-4 mx-3 mb-2">
                  <Button
                    children="Apply"
                    type="submit"
                    className="apply-btn"
                  />
                </div>
              </div>
            </ul>
          </div>
          <div>
            <VscDebugRestart
              style={{
                cursor: "pointer",
                width: "25px",
                height: "25px",
                color: colors.color.secondary,
              }}
              onClick={(e) => {
                setFieldValue("minPrice", "");
                setFieldValue("maxPrice", "");
                setFieldValue("level", "");
                setFieldValue("sortBy", "");
                setFieldValue("searchText", "");
                setFieldValue("location", "areaWise");
                handleSubmit();
                resetForm();
                e.stopPropagation();
              }}
            />
          </div>
        </div>
      </form>
    </Styles>
  );
};

export default FilterDropdowns;
