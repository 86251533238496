import React, { useEffect, useState } from "react";

import Logo from "../../../assets/images/falcon-horizental.png";
import { colors } from "../../../utils/Colors";
import { Styles } from "./header";

import { CgProfile } from "react-icons/cg";
import { GiHamburgerMenu } from "react-icons/gi";

import { Link, useNavigate } from "react-router-dom";

import { useAuthContext } from "../../../features/authentication";
import MessagesDialogTrigger from "../../../features/quickblox/components/MessageDialog";
import RoutesPath from "../../../utils/Paths";
import Notifications from "../Notifications";
import LogoutButton from "./LogoutButton";

const Header = () => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuthContext();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isTop = window.scrollY < 10; // Adjust the value as needed
      setScrolled(!isTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Styles colors={colors}>
      <nav className={scrolled ? "navbar-shadow" : ""}>
        <div className="header-wrapper d-flex align-items-center justify-content-between w-100">
          <Link to="/">
            <img className="falcon-logo" src={Logo} alt="logo" />
          </Link>
          <div className="d-flex align-items-center gap-2">
            <div className="d-md-none">
              <Notifications />
            </div>
            {isAuthenticated && (
              <div>
                <MessagesDialogTrigger />
              </div>
            )}
            <GiHamburgerMenu
              className="hamburger"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
            />
            <div className="nav-content">
              {isAuthenticated ? (
                <div className="menu-options">
                  <div className="px-3 d-none d-md-block">
                    <Notifications />
                  </div>
                  <div className="dropdown">
                    <div
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      className="ms-3 pointer-event"
                    >
                      <div className="d-flex gap-2 align-items-center">
                        <div className="user-img">
                          {user?.profileImg?.imgUrl ? (
                            <img src={user.profileImg.imgUrl} alt={"user"} />
                          ) : (
                            <CgProfile
                              className="profile-icon"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            />
                          )}
                        </div>
                        <div className="text-sm">
                          <p>{user.username}</p>
                          <p className="text-muted text-xs">{user.email}</p>
                        </div>
                      </div>
                    </div>

                    <ul
                      className=" dropdown-menu mt-1 pb-3 p-3 home-dropdown"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <div className="d-flex gap-5">
                        {user.role === "seller" && (
                          <div>
                            <h5 className="heading">Seller</h5>
                            <li
                              className="mb-1"
                              onClick={() => navigate("/sent-offers")}
                              style={{ cursor: "pointer" }}
                            >
                              <p className="dropdown-item ps-1">
                                <span>Sent Offers</span>
                              </p>
                            </li>
                            <li
                              className="mb-1"
                              onClick={() => navigate("/create-gig")}
                              style={{ cursor: "pointer" }}
                            >
                              <p className="dropdown-item ps-1">
                                <span>Create Gig</span>
                              </p>
                            </li>
                            <li
                              className="mb-1"
                              onClick={() => navigate("/seller-orders")}
                              style={{ cursor: "pointer" }}
                            >
                              <p className="dropdown-item ps-1">
                                <span>Selling Orders</span>
                              </p>
                            </li>
                            <li
                              className="mb-1"
                              onClick={() => navigate("/manage-gigs")}
                              style={{ cursor: "pointer" }}
                            >
                              <p className="dropdown-item ps-1">
                                <span>Manage Gigs</span>
                              </p>
                            </li>
                            <li
                              className="mb-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => navigate("/payments")}
                            >
                              <p className="dropdown-item ps-1">
                                <span>Payments</span>
                              </p>
                            </li>
                            <li
                              className="mb-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => navigate("/earnings")}
                            >
                              <p className="dropdown-item ps-1">
                                <span>Earnings</span>
                              </p>
                            </li>
                          </div>
                        )}
                        <div>
                          <h5 className="heading">Buyer</h5>
                          <li
                            className="mb-1"
                            onClick={() => navigate("/incoming-offers")}
                          >
                            <p
                              className="dropdown-item ps-1"
                              style={{ cursor: "pointer" }}
                            >
                              <span>Incoming Offers</span>
                            </p>
                          </li>
                          <li
                            className="mb-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate(RoutesPath.postJob)}
                          >
                            <p className="dropdown-item ps-1">
                              <span>Post A Job</span>
                            </p>
                          </li>
                          <li
                            className="mb-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate(RoutesPath.manageJobs)}
                          >
                            <p className="dropdown-item ps-1">
                              <span>Manage Jobs</span>
                            </p>
                          </li>
                          <li
                            className="mb-1"
                            onClick={() => navigate("/buyer-orders")}
                          >
                            <p
                              className="dropdown-item ps-1"
                              style={{ cursor: "pointer" }}
                            >
                              <span>Buying Orders</span>
                            </p>
                          </li>
                        </div>
                        <div>
                          <h5 className="heading">General</h5>
                          {user.role === "buyer" && (
                            <li
                              className="mb-1"
                              onClick={() => navigate("/become-seller")}
                              style={{ cursor: "pointer" }}
                            >
                              <p className="dropdown-item ps-1">
                                <span>Become Seller</span>
                              </p>
                            </li>
                          )}
                          <li
                            className="mb-1"
                            onClick={() => navigate("/favourites")}
                            style={{ cursor: "pointer" }}
                          >
                            <p className="dropdown-item ps-1">
                              <span>Favorites</span>
                            </p>
                          </li>
                          <li
                            className="mb-1"
                            onClick={() => navigate("/settings")}
                            style={{ cursor: "pointer" }}
                          >
                            <p className="dropdown-item ps-1">
                              <span>Profile Settings</span>
                            </p>
                          </li>
                          <LogoutButton
                            className="dropdown-item ps-1"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              ) : (
                <div className="menu-options gap-3">
                  <Link className="post-job-btn" to="/sign-up">
                    <p>Sign Up</p>
                  </Link>
                  <Link className="post-job-btn" to="/sign-in">
                    <p>Sign In</p>
                  </Link>
                </div>
              )}
            </div>
          </div>

          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div className="offcanvas-header">
              <h5 id="offcanvasExampleLabel" className="falcon-clr">
                Menu
              </h5>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              {isAuthenticated ? (
                <div>
                  <div className="text-md mb-3 d-flex align-items-center gap-3">
                    <div className="mobile-user-img">
                      {user?.profileImg?.imgUrl ? (
                        <img src={user.profileImg.imgUrl} alt={"user"} />
                      ) : (
                        <CgProfile
                          className="profile-icon"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        />
                      )}
                    </div>
                    <div>
                      <p>{user.username}</p>
                      <p className="text-muted text-sm">{user.email}</p>
                    </div>
                  </div>

                  <div className="mb-4">
                    <h5 className="heading mb-1">Buyer</h5>
                    <p
                      className="regular grey-clr mb-1"
                      onClick={() => navigate("/incoming-offers")}
                    >
                      Incoming Offers
                    </p>
                    <p
                      className="regular grey-clr mb-1"
                      onClick={() => navigate(RoutesPath.postJob)}
                    >
                      Post a Job
                    </p>
                    <p
                      className="regular grey-clr mb-1"
                      onClick={() => navigate(RoutesPath.manageJobs)}
                    >
                      Manage Jobs
                    </p>
                    <p
                      className="regular grey-clr mb-1"
                      onClick={() => navigate("/buyer-orders")}
                    >
                      Buying Orders
                    </p>
                  </div>
                  {user.role === "seller" && (
                    <div className="mb-4">
                      <h5 className="heading mb-2">Seller</h5>
                      <p
                        className="regular grey-clr mb-1"
                        onClick={() => navigate("/sent-offers")}
                      >
                        Sent Offers
                      </p>
                      <p
                        className="regular grey-clr mb-1"
                        onClick={() => navigate("/create-gig")}
                      >
                        Create a Gig
                      </p>
                      <p
                        className="regular grey-clr mb-1"
                        onClick={() => navigate("/seller-orders")}
                      >
                        Selling Orders
                      </p>
                      <p
                        className="regular grey-clr mb-1"
                        onClick={() => navigate("/manage-gigs")}
                      >
                        Manage Gigs
                      </p>
                      <p
                        className="regular grey-clr mb-1"
                        onClick={() => navigate("/payments")}
                      >
                        Payments
                      </p>
                      <p
                        className="regular grey-clr mb-1"
                        onClick={() => navigate("/earnings")}
                      >
                        Earnings
                      </p>
                    </div>
                  )}
                  <div className="mb-4">
                    <h5 className="heading mb-1">General</h5>
                    {user.role === "buyer" && (
                      <p
                        className="regular grey-clr mb-1"
                        onClick={() => navigate("/become-seller")}
                      >
                        Become Seller
                      </p>
                    )}
                    <p
                      className="regular grey-clr mb-1"
                      onClick={() => {
                        navigate("/favourites");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Favorites
                    </p>
                    <p
                      className="regular grey-clr mb-1"
                      onClick={() => {
                        navigate("/settings");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Profile Settings
                    </p>
                    <LogoutButton
                      className="regular grey-clr mb-1"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <h5
                    className="text-center p-1"
                    onClick={() => navigate("/sign-in")}
                    style={{ cursor: "pointer" }}
                  >
                    Sign In
                  </h5>
                  <hr />
                  <h5
                    className="text-center p-1"
                    onClick={() => navigate("/sign-up")}
                    style={{ cursor: "pointer" }}
                  >
                    Sign Up
                  </h5>
                  <hr />
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </Styles>
  );
};
export default Header;
