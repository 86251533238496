import {
  createSliceWithStateIds,
  useQueryReduxState,
} from "../../../react-query-redux";
import { useInfiniteQueryRedux } from "../../../react-query-redux/use-inifinite-query-redux";
import { useQBContext } from "../../context/quickblox-provider";
import { listDialogChatMessages } from "../services";

/**
 * @typedef {{ data: T; status: 'success' | 'loading' | 'error'; }} Type<T>
 * @template {object} T
 */

/**
 * @typedef {object} DialogMessage
 * @property {boolean} all_read
 * @property {Array} attachments
 * @property {undefined | boolean} is_sent
 * @property {string} chat_dialog_id
 * @property {string} created_at
 * @property {number} date_sent
 * @property {Array.<number>} delivered_ids
 * @property {string} message
 * @property {number} read
 * @property {Array.<number>} read_ids
 * @property {number} recipient_id
 * @property {number} sender_id
 * @property {string} updated_at
 * @property {string} _id
 */
/**
 * @typedef {Type<Array.<Array<DialogMessage>>>} DialogMessageStateData
 */

const stateKey = "qb-dialog-messages";
export const useDialogMessagesReducer = createSliceWithStateIds(stateKey);
const { actions, reducerName } = useDialogMessagesReducer;
/**
 *
 * @param {object} params
 * @param {string} params.dialogId
 * @param {object} params.initalPageParams
 * @param {number} params.initalPageParams.skip
 * @returns {{messages: DialogMessageStateData, status: string, isFetchingMore: boolean, hasMore: boolean, fetchMore: () => void}}
 */
export const useDialogMessages = ({
  dialogId,
  initalPageParams = { skip: 0 },
}) => {
  const { QBApp } = useQBContext();

  const state = useInfiniteQueryRedux({
    id: [stateKey, { dialogId }],
    fetch: async (params, pageParams) => {
      const [, { dialogId }] = params;
      const { items } = await listDialogChatMessages(
        QBApp,
        dialogId,
        pageParams
      );
      return items;
    },
    initalPageParams,
    getNextPageParams: (allPages, prevPage, prevPageParams) => {
      if (prevPage.length === 0) return undefined;
      const totalLoadedItems = allPages?.flat().length || 0;
      return {
        skip: totalLoadedItems,
      };
    },
    actions,
    reducerName,
  });
  return {
    messages: state.data,
    status: state.status,
    isFetchingMore: state.isFetchingNextPage,
    hasMore: !state.data || state.data.length === 0 ? false : true,
    fetchMore: state.fetchNextPage,
  };
};

export const useDialogMessagesState = () => {
  const { getStateById, setStateById } = useQueryReduxState({
    actions,
    reducerName,
  });

  return {
    /**
     *
     * @typedef {Object} params
     * @property {string} dialogId
     * @returns {DialogMessageStateData | undefined}
     */
    getStateByParams: (params) =>
      getStateById(params ? [stateKey, params] : [stateKey]),
    /**
     * @typedef {function(DialogMessageStateData | undefined):void} setState
     *
     */
    setStateByParams: (
      /** @type {params} */ params,
      /** @type {setState} */ setState
    ) => setStateById([stateKey, params], setState),
  };
};
