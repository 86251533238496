import React from "react";
import { AiTwotoneStar } from "react-icons/ai";
import { FiHeart } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAuthContext } from "../../features/authentication";
import { colors } from "../../utils/Colors";
import { formatNumberWithCommas } from "../../utils/helperFunctions";
import Avatar from "../common/Avatar";

/**
 * @typedef {import("../../types/server").GigDetailData} Props
 */
const GigCard = (/** @type {Props} **/ props) => {
  const navigate = useNavigate();
  const { user, isAuthenticated, toggleFavourites } = useAuthContext();
  const isFav = user?.favGigs?.some((favId) => favId === props._id);

  const handleGigClick = () => {
    window.open(`/gig/${props._id}/detail`, "_blank");
  };

  return (
    <>
      <StyledGigCard colors={colors} onClick={handleGigClick}>
        <img
          className="bg-img"
          src={props.attachments[0]?.imgUrl}
          alt="Background"
        />

        <div className="card-body">
          <div className="card-body-header">
            <h6>{props.createdBy.sellerStats.sellerLevel}</h6>
            {isAuthenticated && (
              <div
                className="fav-icon-bg"
                onClick={(e) => {
                  e.stopPropagation();
                  if (isAuthenticated) {
                    toggleFavourites({ type: "gig", id: props._id });
                  } else {
                    navigate("/sign-in");
                  }
                }}
              >
                <FiHeart
                  className={isFav === true ? "filled fav-icon" : "fav-icon"}
                />
              </div>
            )}
          </div>
          <div>
            <div className="d-flex flex-column">
              <div className="d-flex gap-2">
                <Avatar
                  imgSrc={props.createdBy?.profileImg?.imgUrl}
                  name={props.createdBy?.username}
                  width={40}
                  height={40}
                />
                <div
                  style={{ fontWeight: 600 }}
                  className="overflow-hidden avatar-username"
                >
                  {props.createdBy?._id && (
                    <Link
                      className="text-truncate username-txt"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      to={`/profile/${props.createdBy?._id}?mode=seller`}
                      target="_blank"
                    >
                      {props.createdBy?.username}
                    </Link>
                  )}
                </div>
              </div>
              <div className="card-title">
                <p>{props.title}</p>
              </div>
            </div>
            {/* <div className="card-profile">
              <Avatar 
                imgSrc={props.createdBy?.profileImg?.imgUrl}
                name={props.createdBy?.username}
                width={40}
                height={40}
              />
              <h4>{props.createdBy.username}</h4>
            </div>
            <div className="card-title">
              <p>{props.title}</p>
            </div> */}
            <div className="card-body-footer">
              {props.createdBy.rating > 0 ? (
                <div className="rating">
                  <AiTwotoneStar className="star-icon" />
                  <p>{props.createdBy.rating}</p>
                  <p>{`( ${props.createdBy.sellerStats.ordersCompleted} )`}</p>
                </div>
              ) : null}
              <div></div>
              <div>
                <p className="price">{`$${formatNumberWithCommas(
                  props.priceFrom
                )} - $${formatNumberWithCommas(props.priceTo)}`}</p>
              </div>
            </div>
          </div>
        </div>
      </StyledGigCard>
    </>
  );
};

const StyledGigCard = styled.div`
  position: relative;
  width: 280px;
  height: 260px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.03);
  }
  .username-txt {
    color: white !important;
    font-weight: 400;
  }
  .avatar-username {
    &:hover {
      .username-txt {
        text-decoration: underline !important;
      }
    }
  }
  .bg-img {
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 1) 25%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  h4,
  h6,
  p {
    color: white;
  }
  h6 {
    text-shadow: 0 0 3px ${({ colors }) => colors.color.darkGrey};
  }
  .card-body-header {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
  }
  .card-profile {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .fav-icon-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ colors }) => colors.color.white};
    width: 27px;
    height: 27px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .fav-icon {
    width: 20px;
    height: 20px;

    color: ${({ colors }) => colors.color.primary};
  }
  h6 {
    margin: 0;
  }
  h4 {
    font-size: 16px;
    margin: 0 !important;
  }
  .avatar {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 50% !important;
    width: 40px;
    height: 40px;
    overflow: hidden;
  }
  .avatar img {
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
  .card-title {
    margin: 5px 0;
    padding-bottom: 10px;
    border-bottom: 0.5px solid ${({ colors }) => colors.color.lightGrey};
  }
  .card-body-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .rating {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .star-icon {
    fill: gold;
    width: 20px;
    height: 20px;
  }
  .price {
    font-size: 18px;
  }
  .filled {
    color: red;
    fill: red;
  }
`;

export default GigCard;
