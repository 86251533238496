import {
  createSessionRequest,
  deleteSessionRequest,
  startSessionWithToken,
} from "./api";

const QuickbloxSessionHandlers = ({
  QBApp,
  onSessionCreated,
  onSessionDestroyed,
  onSessionInitalized,
}) => {
  const createQBSession = async (login, password) => {
    const session = await createSessionRequest(QBApp, { login, password });
    onSessionCreated && (await onSessionCreated(session));
    try {
      localStorage.setItem("QBSession", JSON.stringify(session));
    } catch (e) {}
    return session;
  };

  const destroyQBSession = async () => {
    // On Logout, even if error occurs, destroy session
    try {
      await deleteSessionRequest(QBApp);
    } catch (e) {
      console.log(e);
    }
    localStorage.removeItem("QBSession");
    onSessionDestroyed && (await onSessionDestroyed(null));
    return null;
  };

  const initializeQBSession = async ({ user }) => {
    try {
      const session = JSON.parse(localStorage.getItem("QBSession"));
      const initalSession = await startSessionWithToken(QBApp, session.token);
      onSessionInitalized && (await onSessionInitalized(initalSession));
      return session;
    } catch (err) {
      try {
        const session = await createQBSession(
          user?._id,
          user?._id?.toString()?.slice(0, 9)
        );
        onSessionCreated && (await onSessionCreated(session));
        localStorage.setItem("QBSession", JSON.stringify(session));
        return session;
      } catch (err) {
        throw new Error("Failed to create session");
      }
    }
  };

  QBApp.onSessionExpiredListener = async () => {
    onSessionDestroyed && (await onSessionDestroyed(null));
  };

  return {
    createQBSession,
    destroyQBSession,
    initializeQBSession,
  };
};

export default QuickbloxSessionHandlers;
