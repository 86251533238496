import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import StepsImg from "../../../assets/images/gigfalcon-steps.png";
import { useAuthContext } from "../../../features/authentication";
import { openSuccessMessage } from "../../../store/slices/toastSlice";
import { colors } from "../../../utils/Colors";
import { AccountVerificationForm } from "../AccountVerificationForm";
import { SignInForm } from "./SignInForm";
import { Styles } from "./style";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAuthContext();
  return (
    <Styles colors={colors}>
      <div className="signin">
        <div className="person-section flex-fill">
          <div className="person-section-image">
            <img src={StepsImg} alt={"Person"} />
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center flex-fill min-vh-100">
          <div style={{ width: "400px" }}>
            {!user ? (
              <SignInForm
                onSuccess={async (user) => {
                  if (user.accountStatus !== "pending") {
                    navigate("/");
                    //dispatch(openSuccessMessage("Logged In !"));
                  }
                }}
              />
            ) : (
              <AccountVerificationForm
                onSuccess={async () => {
                  navigate("/");
                  dispatch(openSuccessMessage("Account Verified !"));
                }}
                user={user}
              />
            )}
          </div>
        </div>
      </div>
    </Styles>
  );
};
export default SignIn;
