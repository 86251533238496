import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Footer from "../../../components/common/Footer";
import Header from "../../../components/common/Header";
import { getPublicProfile } from "../../../store/thunks/profileThunk";
import { colors } from "../../../utils/Colors";
import { AboutProfilePlaceholder, BuyerProfile } from "./BuyerProfile";
import { SellerProfile } from "./SellerProfile";

/**
 *
 * @typedef {import("../../../types/server").UserProfileData | undefined} ProfileInfoLocationState
 *
 */
const Profile = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const userId = params.profileId;
  const [searchParams] = useSearchParams();
  const profileMode = searchParams.get("mode");
  const { data: userData, loading: userDataLoading } = useSelector(
    (state) => state.profile.getPublicProfile
  );
  // const { gigs: userGigs, loading: gigsLoading } = useGigsSelector();
  // const { data: userReviews, loading: userReviewsLoading } = useSelector(
  //   (state) => state.reviews.getReviews
  // );

  useEffect(() => {
    if (userId) {
      dispatch(getPublicProfile({ userId }));
    }
  }, [dispatch, userId]);

  return (
    <StyledProfile colors={colors}>
      <Header />
      <div className="profile-container">
        <div className="profile-content">
          {userDataLoading ? (
            <AboutProfilePlaceholder />
          ) : userData && (profileMode === "buyer" || !profileMode) ? (
            <BuyerProfile
              userId={userData._id}
              role={userData.role}
              img={userData?.profileImg?.imgUrl}
              username={userData.username}
              rating={userData.rating}
              firstName={userData.name}
              lastName={userData.lastname}
              email={userData.email}
              location={userData.location}
              createdAt={userData.createdAt}
              qbId={userData.qbId}
            />
          ) : userData &&
            profileMode === "seller" &&
            userData.role === "seller" ? (
            <SellerProfile
              userId={userData._id}
              description={userData?.sellerStats?.description}
              role={userData.role}
              img={userData?.profileImg?.imgUrl}
              username={userData.username}
              rating={userData?.sellerStats?.rating}
              firstName={userData.name}
              lastName={userData.lastname}
              email={userData.email}
              location={userData.location}
              createdAt={userData.createdAt}
              qbId={userData.qbId}
              sellerLevel={userData?.sellerStats?.sellerLevel}
            />
          ) : (
            <div className="error-message">{"Error in loading user data"}</div>
          )}
        </div>
      </div>
      <Footer />
    </StyledProfile>
  );
};

const StyledProfile = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${({ colors }) => colors.color.lightGrey}10;
  width: 100%;
  overflow-x: hidden;

  .profile-container {
    flex: 1;
    width: 100%;
    padding: 0;
  }

  .profile-content {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 1024px) {
      flex-direction: row;
      gap: 3rem;
      padding: 2rem 4rem;
    }

    @media (min-width: 1400px) {
      padding: 2rem 6rem;
    }

    @media (min-width: 1800px) {
      padding: 2rem 8rem;
    }
  }

  .error-message {
    text-align: center;
    padding: 2rem;
    background: white;
    border-radius: 12px;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    color: ${({ colors }) => colors.color.darkGrey};
    margin: 1rem auto;
    width: 100%;
    max-width: 500px;
  }
`;

export default Profile;
