import React, { useEffect, useState } from "react";

import { FaStar } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Avatar from "../../../components/common/Avatar";
import LoginPromptModal from "../../../components/common/LoginPromptModal";
import { useAuthContext } from "../../../features/authentication";
import { ContactButton } from "../../../features/quickblox";
import { getCountryFromLatLng } from "../../../services";
import { getReviews } from "../../../store/thunks";
import { colors } from "../../../utils/Colors";
import { formatMessageTime } from "../../../utils/helperFunctions";

/**
 *
 * @typedef Props
 * @property {string} role
 * @property {string} img
 * @property {string} qbId
 * @property {import("../../../types/server").location} location
 * @property {string} username
 * @property {number} rating
 * @property {number | undefined} responseTime
 * @property {boolean} online
 * @property {string} createdAt
 * @property {string | undefined} lastDelivery
 * @property {string | undefined} sellerLevel
 * @property {number | undefined} ordersCompleted
 * @property {boolean} buyer
 * @property {string} userId
 * @property {boolean | undefined} isProVerified
 * @property {string} description
 */
export const BuyerProfile = (
  /** @type Props */ {
    userId,
    img,
    role,
    username,
    rating,
    firstName,
    lastName,
    email,
    location,
    createdAt,
    qbId,
  }
) => {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const dispatch = useDispatch();
  const [addressLocation, setAddressFromLocation] = useState("");
  const { isAuthenticated, user } = useAuthContext();
  const isOwnerProfile =
    isAuthenticated && typeof user != "undefined" && user._id === userId;
  const { data: userReviews, loading: userReviewsLoading } = useSelector(
    (state) => state.reviews.getReviews
  );

  const createdOn = formatMessageTime(createdAt);
  useEffect(() => {
    const profileLocation = async () => {
      try {
        const country = await getCountryFromLatLng(
          location?.latitude,
          location?.longitude
        );
        setAddressFromLocation(country);
      } catch (e) {
        console.log(e);
      }
    };
    profileLocation();
  }, [location]);

  useEffect(() => {
    if (userId) {
      dispatch(getReviews({ createdTo: userId, reviewFor: "buyer" }));
    }
  }, [userId, dispatch]);

  return (
    <Styles colors={colors}>
      <LoginPromptModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
      />
      <div className="about-profile">
        <div className="about-info">
          <div className={false ? " status" : "offline status"}>
            <li>Offline</li>
          </div>
          <div className="about-info-lower-section">
            <Avatar imgSrc={img} name={username} width={100} height={100} />
            <div className="d-flex gap-2 align-items-center">
              <p className="profile-name">{username}</p>
            </div>
          </div>
        </div>
        <div className="profile-info">
          <div className="profile-info-detail">
            <p className="question">Name</p>
            <p className="answer">{firstName}</p>
          </div>
          <div className="profile-info-detail">
            <p className="question">Lastname</p>
            <p className="answer">{lastName}</p>
          </div>
          <div className="profile-info-detail">
            <p className="question">Email</p>
            <p className="answer">{email}</p>
          </div>
          {rating > 0 ? (
            <div className="profile-info-detail">
              <p className="question">Rating</p>
              <p className="answer">{rating}</p>
            </div>
          ) : (
            <div className="profile-info-detail">
              <p className="question">Rating</p>
              <p className="answer">Not rated yet</p>
            </div>
          )}
          <div className="profile-info-detail">
            <p className="question">Location</p>
            <p className="answer">{addressLocation}</p>
          </div>
          <div className="profile-info-detail">
            <p className="question">Member Since</p>
            <p className="answer">{createdOn}</p>
          </div>
          {isAuthenticated ? (
            typeof user !== "undefined" &&
            !isOwnerProfile && (
              <ContactButton
                recipientName={username}
                recipentId={parseInt(qbId) || null}
                label="Contact Me"
                className="w-100"
              />
            )
          ) : (
            <button
              type="button"
              className="btn btn-lg btn-primary mt-4 w-100"
              onClick={() => {
                setShowAuthModal(true);
              }}
            >
              {"Contact Me"}
            </button>
          )}
        </div>
      </div>

      {/* Reviews Section */}
      <div className="reviews-section">
        <h3 className="reviews-title">Reviews</h3>
        {userReviewsLoading ? (
          <div className="loading-spinner">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : userReviews?.length > 0 ? (
          <div className="reviews-container">
            {userReviews.map((review) => (
              <div key={review._id} className="review-card">
                <div className="review-header">
                  <div className="reviewer-info">
                    <Avatar
                      imgSrc={review.createdBy?.profileImg?.imgUrl}
                      name={review.createdBy.username}
                      width={40}
                      height={40}
                    />
                    <div className="reviewer-details">
                      <h4>{review.createdBy.username}</h4>
                      <div className="rating">
                        {[...Array(5)].map((_, index) => (
                          <FaStar
                            key={index}
                            className={
                              index < review.rating
                                ? "star-filled"
                                : "star-empty"
                            }
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <span className="review-date">
                    {formatMessageTime(review.createdAt)}
                  </span>
                </div>
                <p className="review-description">{review.comment}</p>
              </div>
            ))}
          </div>
        ) : (
          <div className="no-reviews">
            <p>No reviews yet</p>
          </div>
        )}
      </div>
    </Styles>
  );
};
export const AboutProfilePlaceholder = () => {
  return (
    <PlaceholderStyles colors={colors}>
      <div className="about-profile">
        <div className="about-info">
          <div
            className="placeholder-glow rounded-pill w-25"
            style={{ height: "20px" }}
          >
            <span className="placeholder placeholder-grey w-100 h-100 rounded-pill"></span>
          </div>
          <div className="about-info-lower-section">
            <div
              className="placeholder-glow rounded-circle"
              style={{ height: "100px", width: "100px" }}
            >
              <span className="placeholder placeholder-grey w-100 h-100 rounded-circle"></span>
            </div>
            <p className="placeholder-glow w-25">
              <span className="placeholder placeholder-grey w-100"></span>
            </p>
          </div>
        </div>
        <hr />
        <div className="profile-info">
          {[...Array(6)].map((_, index) => (
            <div key={index} className="profile-info-detail">
              <p className="placeholder-glow w-25">
                <span className="placeholder placeholder-grey w-100"></span>
              </p>
              <p className="placeholder-glow w-25">
                <span className="placeholder placeholder-grey w-100"></span>
              </p>
            </div>
          ))}
          <div className="w-100 mt-4">
            <div
              className="placeholder-glow w-100 rounded-2"
              style={{ height: "40px" }}
            >
              <span className="placeholder placeholder-grey w-100 h-100 rounded-2"></span>
            </div>
          </div>
        </div>
      </div>
    </PlaceholderStyles>
  );
};

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;

  @media (min-width: 1024px) {
    flex-direction: row;
    gap: 3rem;
  }

  .about-profile {
    background: white;
    border-radius: 12px;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    overflow: hidden;
    width: 100%;
    height: fit-content;

    @media (min-width: 1024px) {
      flex: 0.32;
      position: sticky;
      top: 2rem;
      min-width: 400px;
      max-width: 480px;
    }
  }

  .reviews-section {
    width: 100%;
    background: transparent;
    border-radius: 8px;
    overflow: hidden;

    @media (min-width: 1024px) {
      flex: 0.68;
    }
  }

  .reviews-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    width: 100%;
    padding: 0 1rem;

    @media (min-width: 1024px) {
      padding: 0;
      grid-template-columns: 1fr;
    }

    @media (min-width: 2000px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .review-card {
    background: white;
    padding: 1.5rem;
    border-radius: 12px;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    transition: all 0.3s ease;
    width: 100%;
    min-width: 0;
    max-width: 100%;

    &:hover {
      transform: translateY(-3px);
    }
  }

  .about-info {
    padding: 1.5rem;
    border-bottom: 1px solid ${({ colors }) => colors.color.lightGrey}40;
  }

  .about-info-lower-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 1.5rem 0;
  }

  .profile-info {
    padding: 1.5rem;
  }

  .profile-info-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0;
    border-bottom: 1px solid ${({ colors }) => colors.color.lightGrey}20;

    &:last-child {
      border-bottom: none;
    }
  }

  .question {
    color: ${({ colors }) => colors.color.darkGrey};
    font-weight: 500;
    margin: 0;
  }

  .answer {
    color: ${({ colors }) => colors.color.dark};
    margin: 0;
    font-weight: 400;
  }

  .status {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }

  .status li {
    padding: 0.5rem 1rem;
    color: ${({ colors }) => colors.color.secondary};
    border-radius: 50px;
    border: 1px solid ${({ colors }) => colors.color.secondary};
    font-size: 0.9rem;
    list-style: none;
  }

  .offline li {
    color: ${({ colors }) => colors.color.darkGrey} !important;
    border: 1px solid ${({ colors }) => colors.color.darkGrey} !important;
  }

  .reviews-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: ${({ colors }) => colors.color.dark};
    padding: 0 0.5rem;
  }

  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }

  .no-reviews {
    text-align: center;
    padding: 3rem 2rem;
    color: ${({ colors }) => colors.color.darkGrey};
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

    p {
      font-size: 1.1rem;
      margin: 0;
    }
  }

  .placeholder-profile {
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
  }

  .placeholder-grey {
    background-color: ${({ colors }) => colors.color.lightGrey}40;
  }

  .review-description {
    margin-top: 1rem;
    line-height: 1.6;
    color: ${({ colors }) => colors.color.darkGrey};
    max-width: 100%;
    white-space: normal;
    word-wrap: break-word;
  }

  @media (max-width: 768px) {
    padding: 1rem;

    .about-profile {
      margin: 0;
    }

    .reviews-section {
      margin-top: 2rem;
    }

    .reviews-container {
      grid-template-columns: 1fr;
      gap: 1rem;
      padding: 0;
    }

    .review-card {
      padding: 1.25rem;
    }
  }
`;

const PlaceholderStyles = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .about-profile {
    background: white;
    border-radius: 12px;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    width: 100%;
    max-width: 850px;
  }

  .about-info {
    padding: 1.5rem;
  }

  .about-info-lower-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0;
  }

  .profile-info {
    padding: 1.5rem;
  }

  .profile-info-detail {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 0;
  }

  .placeholder-grey {
    background-color: ${({ colors }) => colors.color.lightGrey}40;
  }

  hr {
    margin: 0;
    opacity: 0.1;
  }

  @media (max-width: 768px) {
    padding: 0 1rem;

    .about-info,
    .profile-info {
      padding: 1rem;
    }
  }
`;

export default BuyerProfile;
