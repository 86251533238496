import React from "react";
import styled from "styled-components";
import { IoClose } from "react-icons/io5";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const ImageCarousel = ({ images, currentIndex, onClose, onNext, onPrev }) => {
  return (
    <CarouselOverlay>
      <CloseButton onClick={onClose}>
        <IoClose size={30} />
      </CloseButton>

      <CarouselContent>
        <NavigationButton onClick={onPrev} position="left">
          <IoIosArrowBack size={30} />
        </NavigationButton>

        <ImageContainer>
          <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
        </ImageContainer>

        <NavigationButton onClick={onNext} position="right">
          <IoIosArrowForward size={30} />
        </NavigationButton>
      </CarouselContent>
    </CarouselOverlay>
  );
};

const CarouselOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const CarouselContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageContainer = styled.div`
  max-width: 90%;
  max-height: 90%;
  img {
    max-width: 100%;
    max-height: 90vh;
    object-fit: contain;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  z-index: 1001;
  padding: 10px;

  &:hover {
    opacity: 0.8;
  }
`;

const NavigationButton = styled.button`
  position: absolute;
  ${(props) => props.position}: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 10px;

  &:hover {
    opacity: 0.8;
  }
`;

export default ImageCarousel;
