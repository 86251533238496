import { authAPI } from "./api";

/**
 * @param {Object} param0
 * @param {Function} param0.onSessionCreated
 * @param {Function} param0.onSessionDestroyed
 * @param {Function} param0.onSessionInitalized
 * * @param {Function} param0.onSessionUpdated
 * @returns {Object}
 * @property {Function} signIn
 * @property {Function} signUp
 * @property {Function} logOut
 * @property {Function} verifyOTP
 * @property {Function} updateProfile
 * @property {Function} toggleFavourites
 * @property {Function} changePassword
 * @property {Function} confirmAccount
 * @property {Function} initalSession
 * @property {Function} becomeSeller
 */

export const ServerSessionHandlers = ({
  onSessionCreated,
  onSessionDestroyed,
  onSessionInitalized,
  onUserUpdated,
}) => {
  /**
   * @param {Object} data
   * @param {string} data.email
   * @param {string} data.password
   * @returns {Object}
   * @property {string} _id
   * @property {string} username
   * @property {string} email
   * @property {string} accountStatus
   * @property {string} createdAt
   * @property {string} updatedAt
   */
  const signIn = async (data) => {
    if (!onSessionCreated) return;
    const response = await authAPI.signIn(data);
    if (response.data.user.accountStatus !== "pending") {
      localStorage.setItem("auth", JSON.stringify(response.data.user));
      localStorage.setItem("x-access-token", response.data.token);
      await onSessionCreated({
        user: response.data.user,
        isAuthenticated: true,
        password: data.password,
      });
    } else {
      await onSessionCreated({
        user: response.data.user,
        isAuthenticated: false,
        password: data.password,
      });
    }
    return response;
  };

  const signInWithGoogle = async (accessToken) => {
    if (!onSessionCreated) return;
    const response = await authAPI.signInWithGoogle(accessToken);
    if (response.data.user.accountStatus !== "pending") {
      localStorage.setItem("auth", JSON.stringify(response.data.user));
      localStorage.setItem("x-access-token", response.data.token);
      await onSessionCreated({
        user: response.data.user,
        isAuthenticated: true,
        password: null,
      });
    } else {
      await onSessionCreated({
        user: response.data.user,
        isAuthenticated: false,
        password: null,
      });
    }
    return response;
  };

  const logOut = async () => {
    try {
      await authAPI.logout();
    } catch (e) {
      console.log(e);
    }
    localStorage.removeItem("auth");
    localStorage.removeItem("x-access-token");
    await onSessionDestroyed({ user: null, isAuthenticated: false });
  };
  const signUp = async (data) => {
    const response = await authAPI.signUp(data);
    if (onSessionCreated) {
      await onSessionCreated({
        user: response.data.user,
        isAuthenticated: false,
        password: data.password,
      });
    }
  };
  const verifyOTP = async (data) => {
    const response = await authAPI.verifyOTP({
      ...data,
    });
    localStorage.setItem("auth", JSON.stringify(response.data.user));
    localStorage.setItem("x-access-token", response.data.token);
    if (onSessionCreated) {
      await onSessionCreated({
        user: response.data.user,
        isAuthenticated: true,
        password: data.password,
      });
    }
    return response;
  };
  const changePassword = async (data) => {
    const response = await authAPI.changePassword(data);
    if (onSessionCreated) {
      await onSessionCreated({
        user: null,
        isAuthenticated: false,
      });
    }
    return response;
  };
  const confirmAccount = async (data) => {
    const response = await authAPI.confirmAccount(data);
    localStorage.getItem("auth", JSON.stringify(response.data.user));
    if (onSessionCreated) {
      await onSessionCreated({
        user: response.data.user,
        isAuthenticated: false,
      });
    }
    return response.data.user;
  };
  const updateProfile = async (payload) => {
    const user = JSON.parse(localStorage.getItem("auth"));
    if (!user) {
      throw new Error("User not found");
    }
    let updatedUser = user;
    try {
      if (payload.profileImg) {
        const response = await authAPI.changeProfilePic(payload.profileImg);
        updatedUser.profileImg = response.data.profileImg;
      }
      if (payload.name || payload.lastname || payload.location) {
        const response = await authAPI.updateProfile(payload);
        updatedUser = {
          ...updatedUser,
          ...response.data.user,
          location: {
            ...updatedUser.location,
            ...response.data.user.location,
          },
          profileImg: {
            ...updatedUser.profileImg,
            ...response.data.user.profileImg,
          },
        };
      }
      if (onUserUpdated) {
        localStorage.setItem("auth", JSON.stringify(updatedUser));
        await onUserUpdated(updatedUser);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * @param {Object} payload
   * @param {string} payload.categoryId
   * @param {string[]} payload.subcategoryIds
   * @param {File} payload.profileImg
   * @param {string[]} payload.languages
   * @param {string} payload.description
   */
  const becomeSeller = async (payload) => {
    const user = await authAPI.becomeSeller(payload);
    if (onUserUpdated) {
      localStorage.setItem("auth", JSON.stringify(user));
      await onUserUpdated(user);
    }
  };

  /**
   *
   * @param {Object} payload
   * @param {String} payload.type
   * @param {String} payload.id
   * @returns {void}
   */
  const toggleFavourites = async (payload) => {
    const user = JSON.parse(localStorage.getItem("auth"));
    if (!user) {
      throw new Error("User not found");
    }
    if (payload.type === "gig") {
      // Optimistic Update
      const existingFavourites = user?.favGigs ?? [];
      const isFavourite = existingFavourites.includes(payload.id);
      const newFavourites = isFavourite
        ? existingFavourites.filter((favId) => favId !== payload.id)
        : [...existingFavourites, payload.id];
      const updatedUser = {
        ...user,
        favGigs: newFavourites,
      };
      localStorage.setItem("auth", JSON.stringify(updatedUser));
      onUserUpdated && (await onUserUpdated(updatedUser));
    }
    try {
      await authAPI.makeFavourite(payload);
    } catch (e) {
      console.log("Error in setting favourtie.");
    }
  };

  const initalSession = async () => {
    if (!onSessionInitalized) return;
    const user = JSON.parse(localStorage.getItem("auth"));
    if (user) {
      await onSessionInitalized({
        user: user,
        isAuthenticated: true,
      });
    } else {
      await onSessionInitalized({
        user: null,
        isAuthenticated: false,
      });
    }
  };

  return {
    signIn,
    signInWithGoogle,
    signUp,
    logOut,
    verifyOTP,
    changePassword,
    initalSession,
    confirmAccount,
    updateProfile,
    toggleFavourites,
    becomeSeller,
  };
};
