import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../utils/Colors";
import Button from "./Button";
import InputField from "./InputField";

const SearchFilter = ({ paramId, locationParamId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(searchParams.get("") || "");
  const [locationChecked, setLocationChecked] = useState(
    searchParams.get(locationParamId) === "global" || false
  );

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const searchText = e.target.searchText.value;
    setSearchParams((prev) => {
      prev.set(paramId, searchText);
      return prev;
    });
  };

  useEffect(() => {
    setValue(searchParams.get(paramId) || "");
  }, [searchParams, paramId]);

  useEffect(() => {
    setLocationChecked(searchParams.get(locationParamId) === "global" || false);
  }, [searchParams, locationParamId]);

  return (
    <form
      className="d-flex align-content-center w-100"
      onSubmit={handleSearchSubmit}
    >
      <Styles colors={colors}>
        <div className="search-bar">
          <div className="input-radio-group">
            <InputField
              className="home-gig-search"
              placeholder="e.g home maintenance"
              name="searchText"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
            <LocationSwitch colors={colors}>
              <div className="d-flex align-items-center locations-wrapper sm-radius">
                <label htmlFor={1} key={1}>
                  <div
                    className="location-label d-flex justify-content-between gap-3"
                    key={1}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <p className="text-sm">Map Area</p>
                    <input
                      type="radio"
                      name="location"
                      id={1}
                      value="areaWise"
                      checked={!locationChecked}
                      onChange={(e) => {
                        const value = e.target.value;
                        setSearchParams((prev) => {
                          prev.set(locationParamId, value);
                          return prev;
                        });
                      }}
                    />
                  </div>
                </label>
                <label htmlFor={2} key={2}>
                  <div
                    className="location-label d-flex justify-content-between gap-3"
                    key={2}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <p className="text-sm">View All</p>
                    <input
                      type="radio"
                      name="location"
                      id={2}
                      value="global"
                      checked={locationChecked}
                      onChange={(e) => {
                        const value = e.target.value;
                        setSearchParams((prev) => {
                          prev.set(locationParamId, value);
                          return prev;
                        });
                      }}
                    />
                  </div>
                </label>
              </div>
            </LocationSwitch>
          </div>

          <Button type="submit" children="Search" className="search-btn" />
        </div>
      </Styles>
    </form>
  );
};

export default SearchFilter;

const LocationSwitch = styled.div`
  .locations-wrapper {
    display: flex;
    align-items: center;
    padding: 9.5px 16px;
    gap: 10px;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    ${"" /* min-width: 140px; */}
  }

  .form-check-input:checked {
    background-color: ${({ colors }) => colors.color.secondary} !important;
    border-color: ${({ colors }) => colors.color.secondary} !important;
  }
  .form-check-input {
    border-color: ${({ colors }) => colors.color.lightGrey} !important;
    height: 20px !important;
    width: 40px !important;
  }
  .form-check-input:focus {
    border-color: none !important;
    background-img: none !important;
    box-shadow: none !important;
  }

  .location-label {
    white-space: nowrap;
  }

  @media (max-width: 767px) {
    .sm-radius {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
`;

const Styles = styled.div`
  .search-bar {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .input-radio-group {
    display: flex;
    ${"" /* gap: 15px; */}
    flex: 1;
    width: 100%;
  }
  @media (max-width: 767px) {
    .search-bar {
      flex-direction: column;
      gap: 10px;
    }

    .sm-radius {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .input-radio-group {
      flex-direction: row;
    }

    .home-gig-search {
      flex: 1;
    }

    .search-btn {
      width: 100%;
      order: 1;
      border-radius: 6px;
    }
  }
  @media only screen and (min-width: 768px) {
    @keyframes slideDown {
      0% {
        transform: translateY(-5%);
      }
      100% {
        transform: translateY(0%);
      }
    }
    .drop-box {
      height: 300px;
      width: 200px;
      position: absolute;
      top: 34px;
      left: 0;
      z-index: 3;
      animation: 0.3s ease-out 0s 1 slideDown;
    }
    .drop-box label {
      font-size: 14px;
    }
  }
`;
