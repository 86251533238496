import { createSlice } from "@reduxjs/toolkit";
import {
  becomeSeller,
  getBuyerProfile,
  getProfileData,
  getPublicProfile,
} from "../thunks/profileThunk";

const handlePending = (key) => (state) => {
  state[key].loading = true;
};
const handleRejected = (key) => (state, action) => {
  state[key].loading = false;
  state[key].error = action.payload;
};
const handleFulfilled = (key) => (state, action) => {
  state[key].loading = false;
  state[key].data = action.payload;
};

const initialState = {
  getUserProfile: {
    data: undefined,
    loading: true,
    error: null,
  },
  getBuyerProfile: {
    data: undefined,
    loading: true,
    error: null,
  },
  becomeSellerMutation: {
    data: undefined,
    loading: false,
    error: null,
  },
  getPublicProfile: {
    data: undefined,
    loading: true,
    error: null,
  },
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfileData.pending, handlePending("getUserProfile"))
      .addCase(getProfileData.fulfilled, handleFulfilled("getUserProfile"))
      .addCase(getProfileData.rejected, handleRejected("getUserProfile"))
      .addCase(getBuyerProfile.pending, handlePending("getBuyerProfile"))
      .addCase(getBuyerProfile.fulfilled, handleFulfilled("getBuyerProfile"))
      .addCase(getBuyerProfile.rejected, handleRejected("getBuyerProfile"))
      .addCase(becomeSeller.pending, handlePending("becomeSellerMutation"))
      .addCase(becomeSeller.fulfilled, handleFulfilled("becomeSellerMutation"))
      .addCase(becomeSeller.rejected, handleRejected("becomeSellerMutation"))
      .addCase(getPublicProfile.pending, handlePending("getPublicProfile"))
      .addCase(getPublicProfile.fulfilled, handleFulfilled("getPublicProfile"))
      .addCase(getPublicProfile.rejected, handleRejected("getPublicProfile"));
  },
});

export default profileSlice.reducer;
