import { Formik, useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import Geocode from "react-geocode";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { FaCamera } from "react-icons/fa";
import { geocodeByPlaceId } from "react-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import styled from "styled-components";
import * as Yup from "yup";
import * as API from "../../api";
import { resetPassword } from "../../api/user";
import AvatarPlaceholderImage from "../../assets/images/avatar-placeholder.png";
import Button from "../../components/common/Button";
import Error from "../../components/common/Error";
import FileUploadTrigger from "../../components/common/FileUploadTrigger";
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
import InputField from "../../components/common/InputField";
import { useAuthContext } from "../../features/authentication";
import { openSuccessMessage } from "../../store/slices/toastSlice";
import { colors } from "../../utils/Colors";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(3, "Minimum length 3")
    .required("Name is required")
    .label("name"),
  lastname: Yup.string()
    .trim()
    .required("Lastname is required")
    .min(3, "Minimum length 3")
    .label("lastname"),
  location: Yup.object().required(),
});

// Add password validation schema
const passwordValidationSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Current password is required"),
  newPassword: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("New password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const SellerInfoSchema = Yup.object().shape({
  category: Yup.object().required("Category is required"),
  subcategories: Yup.array().min(1, "Select at least one subcategory"),
  description: Yup.string()
    .required("Description is required")
    .max(600, "Description too long"),
});

/**
 * @typedef {Object} SellerStats
 * @property {number} totalJobs
 * @property {number} totalEarnings
 * @property {number} averageRating
 * @property {number} completionRate
 * @property {number} responseRate
 * @property {number} onTimeDelivery
 * @property {Date} memberSince
 */

const Settings = () => {
  const dispatch = useDispatch();
  const [initalGeocodeFromLatLng, setInitalGeocodeFromLatLng] =
    useState(undefined);
  const { user, updateProfile } = useAuthContext();
  const [updatingImage, setUpdatingImage] = useState(false);
  const [sellerStats, setSellerStats] = useState(null);

  // Add password-related state
  const [passwordError, setPasswordError] = useState(null);
  const [passwordSuccess, setPasswordSuccess] = useState(null);

  const [activeTab, setActiveTab] = useState("basicInfo");

  const [selectedOption, setSelectedOption] = useState(null);
  const [subCategoryList, setsubCategoryList] = useState([]);
  const { categories } = useSelector((state) => state.categories);

  useEffect(() => {
    const getInitalGeocode = async () => {
      const { latitude, longitude } = user.location;
      try {
        Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
        const response = await Geocode.fromLatLng(latitude, longitude);
        const AddressFirst = response.results.pop();
        const AddressSecond = response.results.pop();
        const AddressThird = response.results.pop();
        if (!AddressThird) {
          if (!AddressSecond) {
            if (!AddressFirst) {
              throw new Error("Address not found");
            } else {
              setInitalGeocodeFromLatLng({
                value: AddressFirst,
                label: AddressFirst.formatted_address,
              });
            }
          } else {
            setInitalGeocodeFromLatLng({
              value: AddressSecond,
              label: AddressSecond.formatted_address,
            });
          }
        } else {
          setInitalGeocodeFromLatLng({
            value: AddressThird,
            label: AddressThird.formatted_address,
          });
        }
      } catch (e) {
        setInitalGeocodeFromLatLng(null);
        console.error(e);
      }
    };
    if (user?.location) {
      getInitalGeocode();
    }
    if (user?.role === "seller") {
      API.getSellerStats()
        .then((res) => {
          const { sellerStats } = res.user;
          setSellerStats(sellerStats);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  const handleSelectChange = (selected) => {
    setFieldValue("category", {
      name: selected.name,
      img: selected.img,
      _id: selected._id,
    });
    setFieldValue("subcategories", "");
    setsubCategoryList(selected.subcategories);
    selected.subcategories ? setSelectedOption(true) : setSelectedOption(false);
  };

  const {
    values: sellerValues,
    errors: sellerErrors,
    touched: sellerTouched,
    handleChange: handleSellerChange,
    handleSubmit: handleSellerSubmit,
    setFieldValue,
    isSubmitting: isSellerSubmitting,
  } = useFormik({
    initialValues: useMemo(() => {
      const category = categories.find(
        (category) => category._id === sellerStats?.category
      );
      const subcategories = category
        ? category.subcategories.filter((sub) =>
            sellerStats?.subcategories.includes(sub._id)
          )
        : [];
      return {
        category,
        subcategories,
        description: sellerStats?.description,
      };
    }, [categories, sellerStats]),
    enableReinitialize: true,
    validationSchema: SellerInfoSchema,
    onSubmit: async (values) => {
      try {
        await API.updateSellerStats({
          ...values,
          category: values.category._id,
          subcategories: values.subcategories.map((sub) => sub._id),
        });
        dispatch(openSuccessMessage("Seller info updated successfully"));
      } catch (error) {
        console.error("Failed to update seller info:", error);
      }
    },
  });

  return (
    <SettingsStyled colors={colors}>
      <Header />
      <section className="container mx-auto settings-container">
        <div className="tabs-container">
          <div className="tabs">
            <button
              className={`tab ${activeTab === "basicInfo" ? "active" : ""}`}
              onClick={() => setActiveTab("basicInfo")}
            >
              Basic Info
            </button>
            {user?.role === "seller" && (
              <button
                className={`tab ${activeTab === "sellerInfo" ? "active" : ""}`}
                onClick={() => setActiveTab("sellerInfo")}
              >
                Seller Info
              </button>
            )}
            <button
              className={`tab ${activeTab === "resetPassword" ? "active" : ""}`}
              onClick={() => setActiveTab("resetPassword")}
            >
              Reset Password
            </button>
          </div>
        </div>

        <div className="settings-content">
          {activeTab === "basicInfo" ? (
            <div className="settings-section">
              <h6>Need to update your public profile?</h6>
              <hr className="mt-0" />
              <div className="setting-fields">
                <div className="d-flex flex-column gap-3 mb-4">
                  <div
                    className="rounded-circle d-flex justify-content-center align-items-center position-relative overflow-hidden"
                    style={{ width: "120px", height: "120px" }}
                  >
                    {updatingImage ? (
                      <div className="w-100 h-100 d-flex justify-content-center align-items-center bg-light">
                        <div
                          className="spinner-border"
                          style={{ color: "gray" }}
                          role="status"
                        ></div>
                      </div>
                    ) : (
                      <>
                        <img
                          src={user.profileImg.imgUrl || AvatarPlaceholderImage}
                          alt={"Profile"}
                          className="w-100 h-100 rounded-circle"
                        />
                        <FileUploadTrigger
                          outerClassName="bg-black d-flex justify-content-center align-items-center position-absolute bottom-0 w-100 h-25"
                          RenderComponent={({ onClick }) => (
                            <div
                              className="bg-black d-flex justify-content-center align-items-center opacity-75"
                              style={{ cursor: "pointer" }}
                              onClick={onClick}
                            >
                              <FaCamera style={{ color: "white" }} />
                            </div>
                          )}
                          onChange={async (e) => {
                            const files = e.target.files;
                            const image = files[0];
                            setUpdatingImage(true);
                            await updateProfile({ profileImg: image });
                            setUpdatingImage(false);
                          }}
                        />
                      </>
                    )}
                  </div>
                  <div>
                    <h5 style={{ color: colors.color.secondary, margin: 0 }}>
                      {user?.username || "Account"}
                    </h5>
                    <p style={{ fontSize: "12px", color: "gray" }}>
                      {user?.email}
                    </p>
                  </div>
                </div>

                {initalGeocodeFromLatLng !== undefined ? (
                  <Formik
                    initialValues={{
                      name: user?.name,
                      lastname: user?.lastname,
                      location: initalGeocodeFromLatLng,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {
                      const payload = { ...values };
                      if (values.location?.value?.place_id) {
                        const response = await geocodeByPlaceId(
                          values.location?.value?.place_id
                        );
                        console.log(response);
                        const {
                          geometry: {
                            location: { lat, lng },
                          },
                        } = response[0];
                        payload.location = {
                          latitude: lat(),
                          longitude: lng(),
                        };
                      }
                      await updateProfile(payload);
                      dispatch(
                        openSuccessMessage("Profile updated successfully")
                      );
                    }}
                    enableReinitialize={true}
                  >
                    {({
                      handleChange,
                      handleSubmit,
                      errors,
                      values,
                      handleBlur,
                      touched,
                      setFieldValue,
                      isSubmitting,
                      dirty,
                    }) => {
                      return (
                        <div>
                          <InputField
                            className="setting-input mb-2"
                            name="name"
                            label="First Name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.name && touched.name && (
                            <Error text={errors.name} />
                          )}
                          <InputField
                            className="setting-input mb-2"
                            name="lastname"
                            label="Last Name"
                            value={values.lastname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.lastname && touched.lastname && (
                            <Error text={errors.lastname} />
                          )}
                          <div>
                            <label>Location</label>
                            <GooglePlacesAutocomplete
                              apiKey={process.env.REACT_APP_MAP_KEY}
                              fetchDetails={true}
                              name="location"
                              selectProps={{
                                value: values.location,
                                onChange: (value) => {
                                  setFieldValue("location", value);
                                },
                                isClearable: true,
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-end">
                            <Button
                              type="submit"
                              disabled={isSubmitting || !dirty}
                              styles={{
                                width: "154px",
                              }}
                              children={
                                isSubmitting ? (
                                  <div
                                    className="spinner-border spinner-border-sm and spinner-grow-sm"
                                    role="status"
                                  >
                                    <span className="sr-only"></span>
                                  </div>
                                ) : (
                                  "Save Changes"
                                )
                              }
                              className="save-changes-btn"
                              onClick={handleSubmit}
                            />
                          </div>
                        </div>
                      );
                    }}
                  </Formik>
                ) : (
                  <p>{"Loading..."}</p>
                )}
              </div>
            </div>
          ) : activeTab === "sellerInfo" ? (
            <div className="settings-section">
              <h6>Technical Information</h6>
              <hr className="mt-0" />
              <div className="setting-fields">
                <form onSubmit={handleSellerSubmit}>
                  <div className="seller-form-section">
                    <div className="mb-3">
                      <label className="form-label">Skill Category</label>
                      <Select
                        id="category"
                        name="category"
                        className="select-field"
                        placeholder="Select Category"
                        options={categories}
                        getOptionLabel={(option) => (
                          <div className="text-capitalize">{option.name}</div>
                        )}
                        getOptionValue={(option) => option._id}
                        onChange={handleSelectChange}
                        value={sellerValues.category}
                      />
                      {sellerErrors.category && sellerTouched.category && (
                        <Error text={sellerErrors.category} />
                      )}
                    </div>

                    <div className="mb-4">
                      <label className="form-label">Subcategories</label>
                      <Select
                        id="subcategories"
                        name="subcategories"
                        className="select-field"
                        placeholder="Select Subcategories..."
                        isMulti
                        options={subCategoryList}
                        getOptionLabel={(option) => (
                          <div className="text-capitalize">{option.name}</div>
                        )}
                        getOptionValue={(option) => option}
                        isDisabled={!selectedOption}
                        onChange={(value) =>
                          setFieldValue("subcategories", value)
                        }
                        value={sellerValues.subcategories}
                      />
                      {sellerErrors.subcategories &&
                        sellerTouched.subcategories && (
                          <Error text={sellerErrors.subcategories} />
                        )}
                    </div>

                    <div className="mb-4">
                      <label className="form-label">Description</label>
                      <textarea
                        id="description"
                        name="description"
                        className="form-textarea"
                        placeholder="Write about yourself here..."
                        onChange={handleSellerChange}
                        value={sellerValues.description}
                      />
                      <div className="d-flex justify-content-between align-items-center">
                        {sellerErrors.description &&
                          sellerTouched.description && (
                            <Error text={sellerErrors.description} />
                          )}
                        <p className="char-count">
                          {sellerValues?.description?.length} /600
                        </p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end">
                      <Button
                        type="submit"
                        disabled={isSellerSubmitting}
                        className="save-changes-btn"
                        children={
                          isSellerSubmitting ? (
                            <div
                              className="spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span className="sr-only"></span>
                            </div>
                          ) : (
                            "Save Changes"
                          )
                        }
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <div className="settings-section">
              <h6>Change Password</h6>
              <hr className="mt-0" />
              <div className="setting-fields">
                <Formik
                  initialValues={{
                    currentPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                  }}
                  validationSchema={passwordValidationSchema}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    try {
                      setPasswordError(null);
                      setPasswordSuccess(null);
                      await resetPassword(
                        values.currentPassword,
                        values.newPassword
                      );
                      setPasswordSuccess("Password updated successfully");
                      resetForm();
                    } catch (error) {
                      setPasswordError(
                        error.message || "Failed to update password"
                      );
                    } finally {
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    values,
                    errors,
                    touched,
                    handleBlur,
                    isSubmitting,
                  }) => (
                    <div>
                      <InputField
                        className="setting-input mb-2"
                        type="password"
                        name="currentPassword"
                        label="Current Password"
                        value={values.currentPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.currentPassword && touched.currentPassword && (
                        <Error text={errors.currentPassword} />
                      )}

                      <InputField
                        className="setting-input mb-2"
                        type="password"
                        name="newPassword"
                        label="New Password"
                        value={values.newPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.newPassword && touched.newPassword && (
                        <Error text={errors.newPassword} />
                      )}

                      <InputField
                        className="setting-input mb-2"
                        type="password"
                        name="confirmPassword"
                        label="Confirm New Password"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.confirmPassword && touched.confirmPassword && (
                        <Error text={errors.confirmPassword} />
                      )}

                      {passwordError && <Error text={passwordError} />}
                      {passwordSuccess && (
                        <div className="alert alert-success">
                          {passwordSuccess}
                        </div>
                      )}

                      <div className="d-flex justify-content-end">
                        <Button
                          type="submit"
                          disabled={isSubmitting}
                          styles={{
                            width: "154px",
                          }}
                          children={
                            isSubmitting ? (
                              <div
                                className="spinner-border spinner-border-sm and spinner-grow-sm"
                                role="status"
                              >
                                <span className="sr-only"></span>
                              </div>
                            ) : (
                              "Reset Password"
                            )
                          }
                          className="save-changes-btn"
                          onClick={handleSubmit}
                        />
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          )}
        </div>
      </section>
      <Footer />
    </SettingsStyled>
  );
};

const SettingsStyled = styled.div`
  .settings-container {
    padding: 2rem 1rem;
    max-width: 800px;
  }

  .tabs-container {
    margin-bottom: 2rem;
  }

  .tabs {
    display: flex;
    gap: 1rem;
    border-bottom: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding-bottom: 1px;
  }

  .tab {
    padding: 0.75rem 1.5rem;
    background: none;
    border: none;
    color: ${({ colors }) => colors.color.darkGrey};
    font-weight: 500;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;

    &:hover {
      color: ${({ colors }) => colors.color.secondary};
    }

    &.active {
      color: ${({ colors }) => colors.color.secondary};

      &:after {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 3px;
        background: ${({ colors }) => colors.color.secondary};
        border-radius: 3px 3px 0 0;
      }
    }
  }

  .settings-content {
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .settings-section {
    padding: 2rem;
  }

  .setting-fields {
    background: white;
    padding: 16px;
    height: 100%;
  }

  .alert {
    margin: 10px 0;
    padding: 10px;
    border-radius: 4px;
  }

  .alert-success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }

  h6 {
    margin-bottom: 0;
  }

  .seller-form-section {
    margin-bottom: 2rem;
  }

  .section-title {
    color: ${({ colors }) => colors.color.secondary};
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
    font-weight: 600;
  }

  .form-label {
    font-size: 14px;
    margin-bottom: 0.5rem;
    color: ${({ colors }) => colors.color.darkGrey};
  }

  .select-field {
    margin-bottom: 0.5rem;
    max-width: 400px;
  }

  .form-textarea {
    width: 100%;
    min-height: 120px;
    padding: 0.75rem;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 4px;
    resize: vertical;
    outline: none;
    font-size: 14px;

    &:focus {
      border-color: ${({ colors }) => colors.color.secondary};
    }
  }

  .char-count {
    font-size: 12px;
    color: ${({ colors }) => colors.color.darkGrey};
    margin: 0.5rem 0 0;
  }

  @media (max-width: 768px) {
    .settings-container {
      padding: 1rem;
    }

    .tabs {
      gap: 0.5rem;
    }

    .tab {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
    }

    .settings-section {
      padding: 1rem;
    }
  }
`;

export default Settings;
