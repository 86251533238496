import styled from "styled-components";

export const Styles = styled.div`
  .filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;
  }
  .dropdowns {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    display: flex;
    align-items: center;
    width: 150px;
    gap: 5px;
    padding: 5px 10px;
    border-radius: 3px;
    position: relative;

    &.location-dropdown {
      width: 180px;
    }
  }
  .dropdown-item:hover {
    background-color: ${({ colors }) => colors.color.lightFalcon};
  }
  .drop-box {
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 20px;
    border-radius: 5px;
    background-color: white;
    z-index: 3;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
  }
  .drop-box label {
    font-size: 20px;
  }
  .drop-form {
    align-content: space-between;
  }

  .chevron1 {
    margin-left: 10px;
  }
  .chevron2 {
    margin-left: 10px;
  }
  .chevron3 {
    margin-left: 40px;
  }
  .filters__chevron {
    border-radius: 2px;
    transition: all 2 linear;
  }

  .filters__chevron.rotate {
    transform: rotate(180deg);
  }
  .filter-inputs {
    max-width: 150px;
    padding: 5px 10px;
    outline: none;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 7px;
  }
  .filter-inputs::-webkit-outer-spin-button,
  .filter-inputs::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .clear-filter {
    font-size: 12px;
    padding: 0 20px;
    color: grey;
    cursor: pointer;
  }
  .locations-wrapper {
    flex-shrink: 0;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 9.5px 16px;
    gap: 10px;
  }

  .form-check-input:checked {
    background-color: ${({ colors }) => colors.color.secondary} !important;
    border-color: ${({ colors }) => colors.color.secondary} !important;
  }
  .form-check-input {
    border-color: ${({ colors }) => colors.color.lightGrey} !important;
    height: 20px !important;
    width: 40px !important;
  }
  .form-check-input:focus {
    border-color: none !important;
    background-img: none !important;
    box-shadow: none !important;
  }

  .location-label {
    white-space: nowrap;
  }

  .search-bar {
    display: flex;
    ${"" /* gap: 15px; */}
    align-items: center;
    flex-direction: row;
  }
  .input-radio-group {
    display: flex;
    ${"" /* gap: 15px; */}
    flex: 1;
    width: 100%;
  }
  @media (max-width: 767px) {
    .search-bar {
      flex-direction: column;
      gap: 10px;
    }

    .sm-radius {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .input-radio-group {
      flex-direction: row;
    }

    .home-gig-search {
      flex: 1;
    }

    .search-btn {
      width: 100%;
      order: 1;
      border-radius: 6px;
    }
  }
  @media only screen and (min-width: 768px) {
    @keyframes slideDown {
      0% {
        transform: translateY(-5%);
      }
      100% {
        transform: translateY(0%);
      }
    }
    .drop-box {
      height: 300px;
      width: 200px;
      position: absolute;
      top: 34px;
      left: 0;
      z-index: 3;
      animation: 0.3s ease-out 0s 1 slideDown;
    }
    .drop-box label {
      font-size: 14px;
    }
  }
`;
