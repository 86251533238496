import React from "react";

import {
  getCustomRecievedOffers,
  rejectRecievedOffer,
} from "../../../store/thunks/offersThunk";
import Button from "../../common/Button";

import { useEffect } from "react";
import { BsHourglassSplit } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { formatNumberWithCommas } from "../../../utils/helperFunctions";

const IncomingCustomOffers = ({ onAccept }) => {
  const dispatch = useDispatch();
  const { data: customOffers, loading } = useSelector(
    (state) => state.offers.getCustomRecievedOffers
  );
  useEffect(() => {
    dispatch(getCustomRecievedOffers());
  }, [dispatch]);

  return (
    <div>
      {loading ? (
        <p className="grey-clr text-center p-5">Loading...</p>
      ) : customOffers?.length === 0 ? (
        <p className="grey-clr text-center p-5">No Offer to Show</p>
      ) : (
        customOffers?.map((offer, index) => {
          return (
            <div
              className="d-flex flex-column flex-sm-row gap-3 offer"
              key={offer._id}
            >
              <div className="d-lg-flex justify-content-between w-100">
                <div className="offer-info">
                  <p className="custom-offer-desc">
                    {offer?.selectedGig?.title}
                  </p>
                  <p className="offer-price ">
                    <span>
                      {offer?.coverLetter.length > 50
                        ? offer?.coverLetter.slice(0, 49) + "..."
                        : offer?.coverLetter}
                    </span>
                  </p>
                  <p className="offer-price mt-2">
                    Offered:{" "}
                    <span>${formatNumberWithCommas(offer?.price)}</span>
                  </p>
                </div>
                <div className="d-flex flex-column flex-sm-row  flex-lg-column justify-content-between align-items-end">
                  <div className="d-flex align-items-center gap-2">
                    <BsHourglassSplit className="grey-clr" />
                    <p className="offer-price">
                      <span>
                        {offer.workingDays > 1
                          ? offer.workingDays + " Days"
                          : offer.workingDays + " Day"}{" "}
                        Delivery
                      </span>
                    </p>
                  </div>

                  {offer.status === "pending" ? (
                    <div className="jutify-self-end d-flex gap-2 mt-2">
                      <Button
                        children="Accept"
                        className="accept-btn"
                        onClick={() => onAccept(offer)}
                      />
                      <Button
                        children={
                          offer.loading ? (
                            <div
                              className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                              role="status"
                            >
                              <span className="sr-only"></span>
                            </div>
                          ) : (
                            "Reject"
                          )
                        }
                        className="reject-btn"
                        onClick={() => dispatch(rejectRecievedOffer(offer))}
                      />
                    </div>
                  ) : (
                    <div
                      className={`justify-self-end d-flex mt-2 offer-status ${offer.status}`}
                    >
                      <p>Rejected</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default IncomingCustomOffers;
