import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import "./styles.css";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import ImageCarousel from "../../common/ImageCarousel";

const GigDetailCarousel = ({ photo }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [showCarousel, setShowCarousel] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageClick = () => {
    setShowCarousel(true);
  };

  const handleClose = () => {
    setShowCarousel(false);
  };

  const handleNext = () => {
    setCurrentImageIndex((prev) => (prev === photo?.length - 1 ? 0 : prev + 1));
  };

  const handlePrev = () => {
    setCurrentImageIndex((prev) => (prev === 0 ? photo?.length - 1 : prev - 1));
  };

  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
          height: "300px",
        }}
        loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        {photo?.map((img, idx) => {
          return (
            <SwiperSlide
              key={idx}
              onClick={() => {
                setCurrentImageIndex(idx);
                setShowCarousel(true);
              }}
            >
              <img
                src={img?.imgUrl}
                style={{
                  objectFit: "cover",
                  backgroundColor: "#f9f9f9",
                  cursor: "pointer",
                }}
                className="d-block w-100 h-100 carousel-img "
                alt="..."
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Swiper
        style={{
          height: "100px",
          minWidth: "300px",
        }}
        onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper d-flex justify-content-center mt-1"
      >
        {photo?.length > 1 &&
          photo?.map((img, idx) => {
            return (
              <SwiperSlide key={idx}>
                <img
                  src={img.imgUrl}
                  className="d-block w-100 h-100"
                  alt="..."
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
      {showCarousel && (
        <ImageCarousel
          images={photo?.map((attachment) => attachment.imgUrl) ?? []}
          currentIndex={currentImageIndex}
          onClose={handleClose}
          onNext={handleNext}
          onPrev={handlePrev}
        />
      )}
    </>
  );
};

export default GigDetailCarousel;
