/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import useHomeFilters from "../../../store/hooks/useHomeFilters";

import { Map, useMap } from "react-mapkit";
import "./style.css";
// import redFalcon from "../../../assets/icons/redIcon.png";
// import blueFalcon from "../../../assets/icons/blueIcon.png"
import { useCallback } from "react";
import { BlueIconSVG, RedIconSVG } from "../../../utils/Images";
import { formatNumberWithCommas } from "../../../utils/helperFunctions";

const DEFAULT_LATITUDE_SPAN = 0.2;
const DEFAULT_LONGITUDE_SPAN = 0.2;

const roleTypes = {
  SELLER: "seller",
  BUYER: "buyer",
};

const getDesc = (description = "") => {
  const limit = 34;
  return description.length <= limit
    ? description
    : `${description.slice(0, limit)}...`;
};

function createCard(
  type,
  username,
  description,
  price,
  rating,
  profilePic,
  onPressDescription = () => {},
  onPressProfileName = () => {}
) {
  const card = document.createElement("div");
  card.className = "card";
  const header = document.createElement("div");
  header.className = "header";

  const profileImg = document.createElement("img");
  profileImg.src = profilePic;
  profileImg.className = "profile-img";

  const usernameElement = document.createElement("p");
  usernameElement.className = "username";
  usernameElement.innerText = username;
  usernameElement.onclick = onPressProfileName;
  header.appendChild(usernameElement);
  header.appendChild(profileImg);

  const ratingContainer = document.createElement("div");
  ratingContainer.className = "rating-container";
  const currentRating = rating;
  const ratingValueElement = document.createElement("span");
  if (currentRating > 0) {
    const ratingTextElement = document.createElement("span");
    ratingTextElement.innerText = "Rating: ";
    ratingContainer.appendChild(ratingTextElement);
    ratingValueElement.className = "rating-value";
  }
  ratingValueElement.innerText =
    currentRating > 0
      ? currentRating
      : type === roleTypes.BUYER
      ? "New Buyer"
      : "Rookie";
  ratingContainer.appendChild(ratingValueElement);
  const content = document.createElement("div");
  content.className = "card-content";
  content.textContent = getDesc(description);
  content.onclick = onPressDescription;

  const footer = document.createElement("div");
  footer.className = "footer-content d-flex justify-content-between";
  const priceContainer = document.createElement("div");
  const priceTextElement = document.createElement("span");
  priceTextElement.innerText = "Price:";
  const priceValueElement = document.createElement("span");
  priceValueElement.innerText = price;
  priceValueElement.className = "price";
  priceContainer.appendChild(priceTextElement);
  priceContainer.appendChild(priceValueElement);
  footer.appendChild(priceContainer);
  footer.appendChild(ratingContainer);
  card.appendChild(header);
  card.appendChild(content);
  card.appendChild(footer);

  return card;
}

const calloutElementForAnnotation = (annotation) => {
  const data = annotation.data;
  const card = createCard(
    data.type,
    data.username,
    data.description,
    `$${data.priceFrom}-$${data.priceTo}`,
    data.rating,
    data.profilePic,
    data.onPressDescription,
    data.onAvatarClick
  );
  const div = document.createElement("div");
  div.appendChild(card);
  return div;
};

const HomeAppleMapView = ({
  filterGigs,
  filterJobs,
  gigs,
  jobs,
  gigsLoading,
  jobsLoading,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [rerender, setRerender] = useState(0);
  const { map, mapProps, mapkit, setRegion } = useMap({
    showsUserLocation: true,
    showsUserLocationControl: true,
  });

  const { filters } = useHomeFilters();

  const setLocation = (
    lat,
    lng,
    latitudeSpan = DEFAULT_LATITUDE_SPAN,
    longitudeSpan = DEFAULT_LONGITUDE_SPAN
  ) => {
    setRegion(
      {
        latitude: lat,
        longitude: lng,
        latitudeSpan: latitudeSpan,
        longitudeSpan: longitudeSpan,
      },
      true
    );
  };

  useEffect(() => {
    setLocation(
      filters.geometryFilter.data.lat,
      filters.geometryFilter.data.lng
    );
  }, [filters.geometryFilter, map]);

  const onMapPointChange = useCallback(() => {
    const visibleMapRect = map.visibleMapRect;
    const { size, origin } = visibleMapRect;
    const halfHeight = size.height / 2;
    const halfWidth = size.width / 2;

    const northEastRect = new mapkit.MapRect(
      origin.x - halfWidth,
      origin.y - halfHeight,
      size.width,
      size.height
    );
    const southWestRect = new mapkit.MapRect(
      origin.x + halfWidth,
      origin.y + halfHeight,
      size.width,
      size.height
    );
    const northEastCoordinates = northEastRect.toCoordinateRegion();
    const southWestCoordinates = southWestRect.toCoordinateRegion();

    const neLat = northEastCoordinates.center.latitude;
    const neLng = northEastCoordinates.center.longitude;
    const swLat = southWestCoordinates.center.latitude;
    const swLng = southWestCoordinates.center.longitude;

    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("neLat", neLat);
      newParams.set("neLng", neLng);
      newParams.set("swLat", swLat);
      newParams.set("swLng", swLng);
      newParams.set("screen-view", "map");
      return newParams;
    });
  }, [map, mapkit]);

  useEffect(() => {
    const neLat = searchParams.get("neLat");
    const neLng = searchParams.get("neLng");
    const swLat = searchParams.get("swLat");
    const swLng = searchParams.get("swLng");
    if (!neLat || !neLng || !swLat || !swLng) {
      return;
    }
    const page = 1;
    const limit = 200;
    const params = {
      neLat,
      neLng,
      swLat,
      swLng,
      page,
      limit,
    };
    const promises = [];
    const gigUrlFilters = {
      searchText: searchParams.get("gigs.searchText") || "",
      sortBy: searchParams.get("gigs.sortBy") || "",
      level: searchParams.get("gigs.level") || "",
      minPrice: searchParams.get("gigs.minPrice") || "",
      maxPrice: searchParams.get("gigs.maxPrice") || "",
    };
    const jobUrlFilters = {
      searchText: searchParams.get("jobs.searchText") || "",
      sortBy: searchParams.get("jobs.sortBy") || "",
      workType: searchParams.get("jobs.workType") || "",
      minPrice: searchParams.get("jobs.min-budgetLimit") || "",
      maxPrice: searchParams.get("jobs.max-budgetLimit") || "",
    };
    if (filters.sectionFilter === "gigs" || filters.sectionFilter === "all") {
      promises.push(filterGigs({ ...params, ...gigUrlFilters }));
    }
    if (filters.sectionFilter === "jobs" || filters.sectionFilter === "all") {
      promises.push(filterJobs({ ...params, ...jobUrlFilters }));
    }
    Promise.all(promises).catch((error) => {
      console.error("Error fetching map data:", error);
    });
  }, [searchParams, filterGigs, filterJobs, filters.sectionFilter]);

  useEffect(() => {
    if (!map) return;

    const addMarkerToMap = (item, isGig = true) => {
      const rating = isGig ? item.rating : item.createdBy.rating;
      const iconSVG = isGig ? BlueIconSVG : RedIconSVG;
      const calloutOffset = isGig ? 3 : 7;

      const pinOptions = {
        url: { 1: iconSVG },
        callout: {
          calloutElementForAnnotation,
        },
        data: {
          id: item._id,
          type: isGig ? roleTypes.SELLER : roleTypes.BUYER,
          userId: isGig ? item.createdBy._id : item.createdBy._id,
          username: isGig ? item.createdBy.username : item.createdBy.username,
          profilePic: isGig
            ? item.createdBy.profileImg?.imgUrl
            : item.createdBy.profileImg?.imgUrl,
          description: isGig ? item.title : item.description,
          priceFrom: formatNumberWithCommas(
            isGig ? item.priceFrom : item.budgetMin
          ),
          priceTo: formatNumberWithCommas(
            isGig ? item.priceTo : item.budgetMax
          ),
          rating,
          onPressDescription: () => {
            window.open(
              `/${isGig ? "gig" : "jobs"}/${item._id}/detail`,
              "_blank"
            );
          },
          onAvatarClick: () => {
            window.open(
              `/profile/${item.createdBy._id}?mode=${
                isGig ? "seller" : "buyer"
              }`,
              "_blank"
            );
          },
        },
      };

      const coordinate = new mapkit.Coordinate(
        isGig ? item.latitude : item.location.latitude,
        isGig ? item.longitude : item.location.longitude
      );

      const factory = () => {
        const img = document.createElement("img");
        img.style = "cursor:pointer";
        img.src = iconSVG;
        return img;
      };

      const annotation = new mapkit.Annotation(coordinate, factory, pinOptions);
      annotation.calloutOffset = new DOMPoint(0, calloutOffset);
      return annotation;
    };

    let timeoutId;

    const setupMap = () => {
      map.removeAnnotations(map.annotations);

      const markers = [];
      const { sectionFilter } = filters;
      if (
        gigs?.length > 0 &&
        (sectionFilter === "gigs" || sectionFilter === "all")
      ) {
        const gigMarkers = gigs.map((gig) => addMarkerToMap(gig, true));
        markers.push(...gigMarkers);
      }

      if (
        jobs?.length > 0 &&
        (sectionFilter === "jobs" || sectionFilter === "all")
      ) {
        const jobMarkers = jobs.map((job) => addMarkerToMap(job, false));
        markers.push(...jobMarkers);
      }

      if (markers.length > 0) {
        map.addAnnotations(markers);
      }

      if (markers.length === 0 && (gigsLoading || jobsLoading)) {
        timeoutId = setTimeout(setupMap, 1000); // Retry after 1 second
      }
    };

    map.addEventListener("region-change-end", onMapPointChange);

    setupMap();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      map.removeAnnotations(map.annotations);
      map.removeOverlays(map.overlays);
      map.removeEventListener("region-change-end", onMapPointChange);
    };
  }, [
    map,
    gigs,
    jobs,
    filters.sectionFilter,
    rerender,
    gigsLoading,
    jobsLoading,
  ]);

  const handleZoomIn = () => {
    const newRegion = new mapkit.CoordinateRegion(
      map.region.center,
      new mapkit.CoordinateSpan(
        map.region.span.latitudeDelta * 0.5,
        map.region.span.longitudeDelta * 0.5
      )
    );
    map.setRegionAnimated(newRegion);
  };

  const handleZoomOut = () => {
    const newRegion = new mapkit.CoordinateRegion(
      map.region.center,
      new mapkit.CoordinateSpan(
        map.region.span.latitudeDelta * 2,
        map.region.span.longitudeDelta * 2
      )
    );
    map.setRegionAnimated(newRegion);
  };

  return (
    <StyledMapView>
      <div className="map position-relative">
        <Map {...mapProps} />
        <div style={{ position: "absolute", top: 50, right: 50 }}>
          <ZoomButton onClick={handleZoomIn}>+</ZoomButton>
          <ZoomButton onClick={handleZoomOut}>-</ZoomButton>
          {gigsLoading || jobsLoading ? <MapLoadingButton /> : null}
        </div>
      </div>
    </StyledMapView>
  );
};

/**
 * @typedef {Object} Coordinates
 * @property {Object} center
 * @property {number} center.lat
 * @property {number} center.lng
 * @property {number} zoom
 *
 *
 * @typedef Props
 * @property {Coordinates} coordinates
 * @property {(newCoordinate:Coordinates) => void} setCoordinates
 */

const ZoomButton = styled.div`
  display: block;
  background-color: rgb(245, 245, 245, 0.9);
  color: rgb(112, 115, 112);
  width: 24px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 8px 8px;
  font-size: 22px;
  text-align: center;
  cursor: pointer;
`;

const MapLoadingButton = styled.div`
  margin-top: 16px;
  width: 24px;
  aspect-ratio: 1;
  border-radius: 50%;

  border: 8px solid #ddf9f5;
  border-right-color: #0fd3bb;
  animation: l2 1s infinite linear;
  @keyframes l2 {
    to {
      transform: rotate(1turn);
    }
  }
`;

const StyledMapView = styled.div`
  .map {
    display: block;
    height: 80vh;
    width: 100vw;
  }
  .map-zoom-buttons {
    position: absolute;
    top: 50;
    right: 7;
  }
  .map-zoom-button {
    width: 4px;
    height: 4px;
    background-color: white;
    border-radius: 10px;
  }
  @media only screen and (min-width: 769px) {
    .map {
      display: block;
      height: 80vh;
      width: 50vw;
    }
  }
`;

export default HomeAppleMapView;
