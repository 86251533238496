import { useFormik } from "formik";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import useGigsSelectorByPagination from "../../store/hooks/useGigsSelectorByPagination";
import { gigsByPaginate } from "../../store/thunks/gigsThunk";
import { colors } from "../../utils/Colors";
import GigPlaceholder from "../common/Placeholders/GigPlaceholder";
import FilterDropdowns from "../Gigs/FilterDropdowns";
import GigCard from "../Gigs/GigCard";

const GigsContainer = ({ mapGigs = [], gigsLoading: loading }) => {
  const scrollViewRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams({
    "gigs.location": "areaWise",
  });
  const dispatch = useDispatch();

  const {
    data: gigs,
    loading: gigsLoading,
    hasMore,
    page,
    limit,
  } = useGigsSelectorByPagination();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  const {
    handleChange,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    resetForm,
  } = useFormik({
    initialValues: useMemo(() => {
      return {
        category: searchParams.get("gigs.category") || "",
        searchText: searchParams.get("gigs.searchText") || "",
        sortBy: searchParams.get("gigs.sortBy") || "",
        level: searchParams.get("gigs.level") || "",
        minPrice: searchParams.get("gigs.minPrice") || "",
        maxPrice: searchParams.get("gigs.maxPrice") || "",
        subcategory: searchParams.get("gigs.subcategory") || "",
        userId: searchParams.get("gigs.userId") || "",
        location: searchParams.get("gigs.location") || "areaWise",
      };
    }, [searchParams]),
    onSubmit: (values) => {
      const params = {};
      searchParams.forEach((value, key) => {
        params[key] = value;
      });
      params["gigs.searchText"] = values.searchText;
      params["gigs.sortBy"] = values.sortBy;
      params["gigs.level"] = values.level;
      params["gigs.minPrice"] = values.minPrice;
      params["gigs.maxPrice"] = values.maxPrice;
      params["gigs.location"] = values.location;
      setSearchParams(params);
    },
  });

  // Add effect to handle filter changes
  useEffect(() => {
    const filters = {
      searchText: searchParams.get("gigs.searchText") || "",
      sortBy: searchParams.get("gigs.sortBy") || "",
      level: searchParams.get("gigs.level") || "",
      minPrice: searchParams.get("gigs.minPrice") || "",
      maxPrice: searchParams.get("gigs.maxPrice") || "",
    };

    // Reset pagination and fetch first page when filters change
    dispatch(gigsByPaginate({ ...filters, page: 1 }));
  }, [dispatch, searchParams]);

  const handleScroll = useCallback(() => {
    if (!hasMore || searchParams.get("gigs.location") !== "global") {
      return;
    }

    const gigUrlFilters = {
      searchText: searchParams.get("gigs.searchText") || "",
      sortBy: searchParams.get("gigs.sortBy") || "",
      level: searchParams.get("gigs.level") || "",
      minPrice: searchParams.get("gigs.minPrice") || "",
      maxPrice: searchParams.get("gigs.maxPrice") || "",
    };

    if (isMobile) {
      const threshold = document.body.offsetHeight * 0.8;
      if (window.innerHeight + window.scrollY >= threshold && !loading) {
        dispatch(gigsByPaginate({ ...gigUrlFilters, page: page + 1 }));
      }
    } else {
      if (scrollViewRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollViewRef.current;
        const threshold = scrollHeight * 0.9;
        if (scrollTop + clientHeight >= threshold && !loading) {
          dispatch(gigsByPaginate({ ...gigUrlFilters, page: page + 1 }));
        }
      }
    }
  }, [hasMore, searchParams, isMobile, loading, dispatch, page]);

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    if (scrollViewRef.current) {
      scrollViewRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);

      if (scrollViewRef.current) {
        scrollViewRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isMobile, handleScroll]);

  return (
    <div className="list" ref={scrollViewRef}>
      <div className="home-dropdown">
        <div className="list-header">
          <h2 className="gigs-title">
            Find The{" "}
            <span>
              Services
              <br />
            </span>{" "}
            You Need
          </h2>
        </div>
        <FilterDropdowns
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          resetForm={resetForm}
        />
      </div>
      <div className="d-flex flex-wrap justify-content-center gap-2">
        {(searchParams.get("gigs.location") || "areaWise") === "areaWise" ? (
          (() => {
            const allGigs = [...(mapGigs || [])];
            return allGigs.length > 0 ? (
              allGigs.map((gig) => <GigCard key={gig._id} {...gig} />)
            ) : loading ? (
              <GigPlaceholder placeholders={[1, 2, 3, 4, 5, 6]} />
            ) : (
              <div
                className="d-flex align-items-center justify-content-center mt-5"
                style={{ color: colors.color.darkGrey }}
              >
                <p>Sorry ... No Gigs Found</p>
              </div>
            );
          })()
        ) : (
          <>
            {!!gigs?.length &&
              gigs?.map((gig, index) => <GigCard key={index} {...gig} />)}
            {gigsLoading ? (
              <GigPlaceholder placeholders={[1, 2, 3, 4, 5, 6]} />
            ) : (
              !gigsLoading &&
              !gigs?.length && (
                <div
                  className="d-flex align-items-center justify-content-center mt-5"
                  style={{ color: colors.color.darkGrey }}
                >
                  <p>Sorry ... No Gig Found</p>
                </div>
              )
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default GigsContainer;
