import { QuickbloxSessionHandlersWithChat } from "../quickblox";
import { ServerSessionHandlers } from "../server-authentication";

/**
 * SessionHandlers function that returns ServerSessionHandlers with QuickbloxSessionHandlersWithChat.
 * @param {Object} props - The props object.
 * @param {Object} props.QBApp - The Quickblox app object.
 * @param {Function} props.onSessionInitalized - The function to be called when session is initialized.
 * @param {Function} props.onSessionCreated - The function to be called when session is created.
 * @param {Function} props.onSessionDestroyed - The function to be called when session is destroyed.
 *  @param {Function} props.onUserUpdated - The function to be called when session is destroyed.
 * @returns {Object} - The ServerSessionHandlers object.
 * @property {Function} signIn - The function to sign in.
 * @property {Function} signUp - The function to sign up.
 * @property {Function} logOut - The function to log out.
 * @property {Function} verifyOTP - The function to verify OTP.
 * @property {Function} changePassword - The function to verify OTP.
 * @property {Function} initalSession - The function to initialize session.
 */
export const SessionHandlers = ({ QBApp, ...props }) => {
  const { createQBSession, destroyQBSession, initializeQBSession } =
    QuickbloxSessionHandlersWithChat({
      QBApp,
      onSessionDestroyed: async () => {
        props.onSessionDestroyed &&
          (await props.onSessionDestroyed({
            user: null,
            isAuthenticated: false,
          }));
      },
    });
  return ServerSessionHandlers({
    onSessionInitalized: async ({ user, isAuthenticated }) => {
      try {
        if (isAuthenticated) {
          const QBSession = await initializeQBSession({ user });
          props.onSessionInitalized &&
            (await props.onSessionInitalized({
              user: {
                ...user,
                quickblox: QBSession,
              },
              isAuthenticated,
            }));
        } else {
          await props.onSessionInitalized({
            user: null,
            isAuthenticated: false,
          });
        }
      } catch (e) {
        await props.onSessionInitalized({ user: null, isAuthenticated: false });
      }
    },
    onSessionCreated: async ({ user, isAuthenticated }) => {
      if (isAuthenticated) {
        const QBSession = await createQBSession(
          user._id,
          user._id.toString().slice(0, 9)
        );
        props.onSessionCreated &&
          (await props.onSessionCreated({
            user: {
              ...user,
              quickblox: QBSession,
            },
            isAuthenticated: true,
          }));
      } else {
        props.onSessionCreated &&
          (await props.onSessionCreated({
            user,
            isAuthenticated: false,
          }));
      }
    },
    onSessionDestroyed: async ({ user, isAuthenticated }) => {
      await destroyQBSession();
      props.onSessionDestroyed &&
        (await props.onSessionDestroyed({ user, isAuthenticated }));
    },
    onUserUpdated: async (user) => {
      if (props.onUserUpdated) {
        await props.onUserUpdated(user);
      }
    },
  });
};
