import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { getInvoices } from "../../../store/thunks";
const PendingPaymentsSection = () => {
  const [showDetails, setShowDetails] = useState(true);
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.invoice.getInvoices);
  useEffect(() => {
    dispatch(getInvoices());
  }, [dispatch]);

  return (
    <div>
      <div
        className="earnings-foot-section"
        onClick={() => setShowDetails(!showDetails)}
      >
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="mb-0">Earnings</h5>
          <FiChevronDown />
        </div>
      </div>
      {showDetails && (
        <div>
          {loading ? (
            <div>Loading...</div>
          ) : (
            data?.map((invoice) => (
              <div className="earnings-foot-content d-flex justify-content-between align-items-center">
                <div>
                  <h6 className="mb-0 text-md">
                    Order <strong>#{invoice?.resourceId?.searchId}</strong>
                  </h6>
                  <p className="mb-0 text-muted text-sm">
                    <strong>Available On</strong>:
                    {format(new Date(invoice?.arrivalDate), "dd MMM yyyy")}
                  </p>
                </div>
                <div>
                  <h6 className="mb-0 text-md">
                    <strong>Net</strong>: {`$${invoice?.transferPrice}`}
                  </h6>
                  <p className="mb-0 text-muted text-sm">
                    <strong>Order Amount: </strong>
                    {`$${invoice?.resourceId?.price}`}
                  </p>
                  <p className="mb-0 text-muted text-sm">
                    <strong>Service Fee: </strong>
                    {`$${invoice?.platformCharges}`}
                  </p>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default PendingPaymentsSection;
