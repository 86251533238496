import React, { useEffect, useState } from "react";

import { isFulfilled } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ImgPlace from "../../assets/images/img-placeholder.jpeg";
import Button from "../../components/common/Button";
import Error from "../../components/common/Error";
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
import Rating from "../../components/common/Rating";
import { completeOrder, getBuyerOrders, rejectOrder } from "../../store/thunks";
import { colors } from "../../utils/Colors";
import {
  formateDate,
  formatNumberWithCommas,
} from "../../utils/helperFunctions";
import { OrderDetailStyledDiv } from "./OrderStyledDiv";
import useCreateReviewModal from "./useCreateReviewModal";

const OrderDetail = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [comment, setComments] = useState("");
  const data = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { orders, completeLoading, rejectLoading } = useSelector(
    (state) => state.orders
  );
  const order = orders.find((order) => order._id === params.orderId) || null;
  const locOrder = data.state;
  const orderId = order?._id || locOrder?._id;
  const createdAt = order?.createdAt || locOrder?.createdAt;
  const delivery = order?.deliveryDate || locOrder?.deliveryDate;
  const workType = order?.job?.workType || locOrder?.job?.workType;
  const status = order?.status || locOrder?.status;
  const revisions = order?.revisions || locOrder?.revisions;
  const revisionsReverse = revisions ? [...revisions].reverse() : [];
  const description = order?.job?.description || locOrder?.job?.description;
  const price = order?.price || locOrder?.price;
  //const budgetMin = order?.job?.budgetMin || locOrder?.job?.budgetMin;
  //const budgetMax = order?.job?.budgetMax || locOrder?.job?.budgetMax;
  const orderNo = order?.searchId || locOrder?.searchId;
  const isRevision = order?.isRevision || locOrder?.isRevision;
  const rating = order?.createdBy?.rating || locOrder?.createdBy?.rating;
  //const role = order?.createdBy?.role || locOrder?.createdBy?.role;
  const sellerName =
    order?.createdTo?.username || locOrder?.createdTo?.username;
  //const buyerName = order?.createdBy?.username || locOrder?.createdBy?.username;
  const sellerImg =
    order?.createdTo?.profileImg?.imgUrl ||
    locOrder?.createdTo?.profileImg?.imgUrl;
  const buyerImg =
    order?.createdBy?.profileImg?.imgUrl ||
    locOrder?.createdBy?.profileImg?.imgUrl;
  const jobImg =
    order?.job?.attachments[0].imgUrl || locOrder?.job?.attachments[0].imgUrl;

  const { openReviewModal, reviewModal } = useCreateReviewModal({
    gigId: order?.job?._id || locOrder?.job?._id,
    orderId: order?._id || locOrder?._id,
  });
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    dispatch(getBuyerOrders());
  }, [dispatch]);

  return (
    <div>
      {reviewModal}
      <Header />
      <OrderDetailStyledDiv colors={colors}>
        <div className="container order-details-page">
          <div className="d-flex flex-wrap gap-2 justify-content-between mb-3">
            <div className="d-flex gap-3 align-items-center">
              <h3 className="mb-0">
                Order #<span className="falcon-clr">{orderNo}</span>
              </h3>
              {order && (
                <span className="badge bg-success">
                  {status === "completed" ? "Completed" : "Active"}
                </span>
              )}
            </div>

            {status !== "completed" && (
              <div className="d-flex gap-2">
                <Button
                  className="accept-btn complete-btn"
                  onClick={async () => {
                    const completingOrderAction = await dispatch(
                      completeOrder({ orderId })
                    );
                    if (isFulfilled(completingOrderAction)) {
                      console.log("completed");
                      await openReviewModal();
                      navigate("/buyer-orders");
                    }
                  }}
                >
                  {completeLoading ? (
                    <div
                      className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  ) : (
                    "Mark as Complete"
                  )}
                </Button>
                {isRevision && (
                  <Button
                    className="reject-btn"
                    onClick={toggle}
                    //disabled={!isRevision}
                  >
                    {"Reject"}
                  </Button>
                )}
              </div>
            )}
          </div>
          <div className="d-flex flex-wrap gap-3">
            <div
              className="order-wrapper flex-fill"
              style={{ maxWidth: "50%" }}
            >
              <div className="d-flex flex-column flex-sm-row justify-content-between pb-3 gap-3">
                <div className="d-flex  gap-2 order-2 order-sm-1">
                  <div className="avatar">
                    <img src={sellerImg} alt={"seller-profile"} />
                  </div>
                  <div>
                    <div className="d-flex flex-wrap gap-1 gap-sm-3">
                      <p className="seller-name">{sellerName}</p>
                      {rating > 0 && <Rating rating={rating} />}
                    </div>
                    <p className="seller-level">Seller</p>
                  </div>
                </div>
              </div>
              <hr />
              <div className="d-flex flex-column flex-sm-row justify-content-between ">
                <div>
                  <p className="order-headings">
                    Ordered date: <span>{formateDate(createdAt)}</span>
                  </p>
                  <p className="order-headings">
                    Price: <span>${formatNumberWithCommas(price)}</span>
                  </p>
                </div>
                <div>
                  <p className="order-headings">
                    Delivery Date: <span>{formateDate(delivery)}</span>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="order-wrapper flex-fill"
              style={{ maxWidth: "50%" }}
            >
              <h4>
                <span className="falcon-clr">Job</span> Details
              </h4>
              <div className="job-detail">
                <div className="order-img">
                  <img src={jobImg || ImgPlace} alt={"order"} />
                </div>
                <div>
                  <h5 className="order-headings">{description}</h5>
                  <p className="order-headings">
                    Work type: <span>{workType}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {revisionsReverse?.length > 0 && (
            <div className="mt-5">
              <h3 className="mb-0">Revisions</h3>
              <div className="accordion border p-4 rounded-4 mt-3">
                {revisionsReverse.map((revision, idx) => {
                  return (
                    <div
                      className="accordion-item border-0 bg-transparent"
                      key={idx}
                    >
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button border-0 border-bottom bg-transparent shadow-none collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse-${idx}`}
                          aria-expanded="false"
                          aria-controls={`collapse-${idx}`}
                        >
                          <div className="d-flex w-100 gap-3 align-items-center">
                            <div className="avatar">
                              <img src={sellerImg} alt={"avatar"} />
                            </div>
                            <div className="d-flex align-items-baseline gap-1">
                              <p>
                                <span className="falcon-clr">{sellerName}</span>
                                {` has delivered the order`}
                              </p>
                              {revision.delivery && (
                                <span className="seller-level">
                                  {format(
                                    new Date(revision.delivery.createdAt),
                                    "MMM dd, p"
                                  )}
                                </span>
                              )}
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id={`collapse-${idx}`}
                        className="accordion-collapse collapse border-0"
                        style={{
                          marginLeft: "24px",
                        }}
                        aria-labelledby="headingOne"
                      >
                        <div className="accordion-body p-0">
                          <div
                            className="p-3"
                            style={{
                              borderLeft: "1px solid rgb(229, 229, 229)",
                            }}
                          >
                            {revision.delivery.details}
                          </div>
                          {revision.comment ? (
                            <div className="accordion">
                              <div className="accordion-item border-0 bg-transparent">
                                <h2
                                  className="accordion-header"
                                  id="headingOne"
                                >
                                  <button
                                    className="accordion-button border-0 p-0 border-bottom bg-transparent shadow-none collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse-comment-${idx}`}
                                    aria-expanded="false"
                                    aria-controls={`collapse-comment-${idx}`}
                                  >
                                    <div
                                      className="d-flex w-100 gap-3 align-items-center p-2"
                                      style={{
                                        borderLeft:
                                          "1px solid rgb(229, 229, 229)",
                                      }}
                                    >
                                      <div className="avatar">
                                        <img src={buyerImg} alt={"avatar"} />
                                      </div>
                                      <div className="d-flex align-items-baseline gap-1">
                                        <p>{"You rejected this delivery"}</p>
                                        {revision.comment.createdAt && (
                                          <span className="seller-level">
                                            {format(
                                              new Date(
                                                revision.comment.createdAt
                                              ),
                                              "MMM dd, p"
                                            )}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </button>
                                </h2>
                                {revision.comment.details && (
                                  <div
                                    id={`collapse-comment-${idx}`}
                                    className="accordion-collapse collapse border-0"
                                    style={{
                                      marginLeft: "24px",
                                    }}
                                    aria-labelledby="headingOne"
                                  >
                                    <div
                                      className="p-3"
                                      style={{
                                        borderLeft:
                                          "1px solid rgb(229, 229, 229)",
                                      }}
                                    >
                                      {revision.comment.details}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Add Comment</ModalHeader>
            <ModalBody>
              <div className="form-floating">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                  style={{ height: "150px" }}
                  value={comment}
                  onChange={(e) => setComments(e.target.value)}
                ></textarea>
                <Error text={comment.length < 1 && "Comment is Required"} />
                <label htmlFor="floatingTextarea2">Comment</label>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button className="go-offline px-3 py-1" onClick={toggle}>
                Cancel
              </Button>
              <Button
                children={
                  rejectLoading ? (
                    <div
                      className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  ) : (
                    "Reject"
                  )
                }
                disabled={comment.length === 0 && true}
                className="reject-btn px-3 py-1 reject-order-btn"
                onClick={() =>
                  dispatch(
                    rejectOrder({ data: { orderId, comment }, navigate })
                  )
                }
              />
            </ModalFooter>
          </Modal>
        </div>
      </OrderDetailStyledDiv>
      <Footer />
    </div>
  );
};

export default OrderDetail;
