import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import useHomeFilters from "../../../store/hooks/useHomeFilters";
import { getJobs } from "../../../store/thunks/jobsThunk";
import { colors } from "../../../utils/Colors";
import AppleAutoComplete from "../../common/AppleAutoComplete";
import { Styles } from "./locationFilter";

const LocationFilter = () => {
  const {
    filters: { sectionFilter },
    handleUpdatingFilters,
  } = useHomeFilters();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getJobs());
  }, [dispatch]);

  const onSelectPlaces = (result) => {
    handleUpdatingFilters({
      applePlaceFilter: result.value,
    });
  };

  return (
    <Styles colors={colors}>
      <div className="location-filter">
        <div className="location-filter-wrapper">
          <div className="search-section">
            <div className="search-location">
              <AppleAutoComplete onSelect={onSelectPlaces} />
            </div>
          </div>

          <div className="switches-section">
            <div className="locations-wrapper">
              <label>Sellers</label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault1"
                  style={{ cursor: "pointer" }}
                  onChange={(e) => {
                    e.target.checked
                      ? handleUpdatingFilters(
                          sectionFilter === "jobs"
                            ? { sectionFilter: "all" }
                            : { sectionFilter: "gigs" }
                        )
                      : handleUpdatingFilters({ sectionFilter: "jobs" });
                  }}
                  checked={sectionFilter === "gigs" || sectionFilter === "all"}
                />
              </div>
            </div>
            <div className="locations-wrapper">
              <label>Buyers</label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault2"
                  style={{ cursor: "pointer" }}
                  onChange={(e) => {
                    e.target.checked
                      ? handleUpdatingFilters(
                          sectionFilter === "gigs"
                            ? { sectionFilter: "all" }
                            : { sectionFilter: "jobs" }
                        )
                      : handleUpdatingFilters({ sectionFilter: "gigs" });
                  }}
                  checked={sectionFilter === "jobs" || sectionFilter === "all"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default LocationFilter;
