import React from "react";
import { FaUserLock } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../utils/Colors";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
  position: relative;
  animation: slideIn 0.3s ease-out;

  @keyframes slideIn {
    from {
      transform: translateY(-100px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: ${colors.gray};

  &:hover {
    color: ${colors.primary};
  }
`;

const LoginPromptModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>
          <IoMdClose />
        </CloseButton>

        <div className="text-center">
          <div className="mb-4">
            <FaUserLock size={50} color={colors.primary} className="mx-auto" />
          </div>

          <h2 className="text-xl font-bold mb-3">Login Required</h2>

          <p className="text-gray-600 mb-6">
            Please log in to contact and explore all our features
          </p>

          <div className="d-flex justify-content-center mt-4">
            <button
              className="btn btn-primary px-5 py-2 me-2"
              onClick={() => {
                navigate("/sign-in");
              }}
            >
              Log In
            </button>

            <button
              className="btn btn-outline-secondary px-5 py-2"
              onClick={() => {
                navigate("/sign-up");
              }}
            >
              Sign Up
            </button>
          </div>
        </div>
      </ModalContent>
    </ModalOverlay>
  );
};

export default LoginPromptModal;
