import React from "react";
import { useUserDialogs } from "../../../api";
import ChatsListItem from "./ChatsListItem";
import { MessageStateFilter } from "./ClientSideFilters/MessageStateFilter";

const ChatsList = ({ selectedDialogId, onSelectDialogId }) => {
  const { dialogs, status } = useUserDialogs();
  if (status === "error") {
    return <div className="p-2">Error In Dialogs...</div>;
  }
  if (status === "loading") {
    return (
      <div className="p-2 d-flex align-items-center h-100 justify-content-center">
        <div className="spinner-border text-secondary" role="status">
          <span className="sr-only d-none">Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <div className="p-2">
      <div
        style={{
          height: "calc(100vh - 120px)",
          overflow: "auto",
        }}
      >
        <MessageStateFilter
          chats={dialogs}
          render={({ chats }) => {
            return (
              <div className="pt-1 pb-1">
                {chats.map((dialog) => {
                  const { opponent } = dialog;
                  const { id, full_name, email } = opponent;

                  return (
                    <ChatsListItem
                      key={id}
                      onClick={() => {
                        onSelectDialogId(dialog._id);
                      }}
                      selected={selectedDialogId === dialog._id}
                      unread_messages_count={dialog.unread_messages_count}
                      senderName={full_name || email}
                      lastMessageDate={dialog.last_message_date_sent}
                      lastMessageTxt={
                        dialog.last_message
                          ? dialog.last_message.length > 60
                            ? `${dialog.last_message.slice(0, 60)}...`
                            : dialog.last_message
                          : null
                      }
                      lastMessageId={dialog.last_message_id}
                    />
                  );
                })}
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default ChatsList;
