import React, { useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import StepsImg from "../../../assets/images/gigfalcon-steps.png";
import Button from "../../../components/common/Button";
import InputField from "../../../components/common/InputField";
import { useAuthContext } from "../../../features/authentication";
import { openSuccessMessage } from "../../../store/slices/toastSlice";
import { colors } from "../../../utils/Colors";
import ChangePasswordForm from "./ChangePasswordForm";
import { Styles } from "./style";
import { VerifyEmailAccountForm } from "./VerifyEmailAccountForm";

const Forgetpassword = () => {
  // 1: verify email, 2: verify otp, 3: change password
  const [screen, setScreen] = useState(1);
  const [verifyCode, setVerifyCode] = useState("");
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // //When email is verififed or error comes at otp verification move to section 2
  // useEffect(() => {
  //   dispatch(setSectionFP(1));
  // }, [dispatch]);

  return (
    <Styles colors={colors}>
      <div className="signin">
        <div className="person-section flex-fill">
          <div className="person-section-image">
            <img src={StepsImg} alt={"Person"} />
          </div>
        </div>
        {screen === 1 && (
          <VerifyEmailAccountForm
            onSuccess={() => {
              setScreen(2);
            }}
          />
        )}
        {screen === 2 && (
          <div className="main-section">
            <div className="main-content">
              <IoArrowBack
                className="mb-2"
                style={{
                  color: `${colors.color.secondary}`,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setScreen(1);
                }}
              />
              <h2 className="mb-3">Verify your Account</h2>
              <div className="alert alert-primary mb-4 " role="alert">
                {"We've sent a verification code to your email address"}
              </div>
              <InputField
                id="verifyCode"
                name="verifyCode"
                className="signup-input"
                placeholder="Enter Verification Code"
                value={verifyCode}
                onChange={(e) => setVerifyCode(e.target.value)}
              />
              <Button
                className="continue"
                onClick={() => {
                  setScreen(3);
                }}
              >
                <p style={{ color: "white", fontSize: "15px" }}>Next</p>
              </Button>
            </div>
          </div>
        )}
        {screen === 3 && user && (
          <ChangePasswordForm
            verifyCode={verifyCode}
            userId={user._id}
            onSuccess={(message) => {
              navigate("/sign-in");
              dispatch(openSuccessMessage(message));
            }}
          />
        )}
      </div>
    </Styles>
  );
};

export default Forgetpassword;
